import request from "../utils/request"
//获取短信验证码
export const sms = params => {
  return request({
    url: "/sms",
    method: 'get',
    params
  })
}
//个人会员注册
export const memberRegister = data => {
  return request({
    url: "/login/register",
    method: 'post',
    data
  })
}
//企业会员注册
export const groupRegister = data => {
  return request({
    url: "/login/companyRegister",
    method: 'post',
    data
  })
}
//企业子会员注册
export const groupSubRegister = data => {
  return request({
    url: "/login/companySubRegister",
    method: 'post',
    data
  })
}
//子会员注册获取企业信息
export const getGroupByClasses = params => {
  return request({
    url: "/login/queryGroupList",
    method: 'get',
    params
  })
}
//根据类型查询会费标准信息
export const getExpensesByClasses = params => {
  return request({
    url: "/login/queryExpensesInfo",
    method: 'get',
    params
  })
}
//手机号验证码登录
export const mobileCodeLogin = params => {
  return request({
    url: "/login/mobileCodeLogin",
    method: 'get',
    params
  })
}
//个人手机号密码登录
export const memberLogin = params => {
  return request({
    url: "/login/phonePwdLogin",
    method: 'get',
    params
  })
}
//企业账户密码登录
export const groupLogin = params => {
  return request({
    url: "/login/namePwdLogin",
    method: 'get',
    params
  })
}
//获取用户openid
export const getOpenId = params => {
  return request({
    url: "/login/wxOpenId",
    method: 'get',
    params
  })
}
//获取志愿者信息
export const getVolunteer = params => {
  return request({
    url: "/login/queryVolunteer",
    method: 'get',
    params
  })
}
//会员交费
export const payMembershipFee = data => {
  return request({
    url: "/pay/newWeixinH5Pay",
    method: 'post',
    data
  })
}
//查询数据库中保存短信验证码
export const getVcode = params => {
  return request({
    url: "/vcode",
    method: 'get',
    params
  })
}
//根据party_id查询人员信息
export const queryPersonInfoByPartyId = params => {
  return request({
    url: "/login/queryPersonInfoByPartyId",
    method: 'get',
    params
  })
}
//根据party_id查询人员信息
export const queryGroupInfoByPartyId = params => {
  return request({
    url: "/login/queryGroupInfo",
    method: 'get',
    params
  })
}
//企业查询子会员信息
export const querySubInfoByPartyId = params => {
  return request({
    url: "/login/querySubInfoByCompanyId",
    method: 'get',
    params
  })
}
//企业删除子会员信息
export const deleteSubInfoByPartyId = params => {
  return request({
    url: "/login/deleteSubInfoByPartyId",
    method: 'get',
    params
  })
}
//企业添加子会员信息
export const addSubInfoByPartyId = params => {
  return request({
    url: "/login/addSubInfoByPartyId",
    method: 'get',
    params
  })
}
//根据会员号查询会员信息
export const queryPersonInfoByMemberCode = params => {
  return request({
    url: "/login/queryPersonInfoByMemberCode",
    method: 'get',
    params
  })
}
//添加订单信息
export const addOrderInfo = data => {
  return request({
    url: "/pay/insertOrder",
    method: 'post',
    data
  })
}
//更新起始日期和有效期
export const updateStartInvalidDate = params => {
  return request({
    url: "/pay/updateStartInvalidDate",
    method: 'get',
    params
  })
}
//更新有效期
export const updateInvalidDate = params => {
  return request({
    url: "/pay/updateInvalidDate",
    method: 'get',
    params
  })
}
//查询人员信息接口
export const queryCustomByPartyId = params => {
  return request({
    url: "/queryCustomByPartyId",
    method: 'get',
    params
  })
}
//查询人员全部信息接口
export const queryPersonAllInfoByPartyId = params => {
  return request({
    url: "/login/queryPersonAllInfoByPartyId",
    method: 'get',
    params
  })
}
//编辑人员信息接口
export const updatePersonAllInfoByPartyId = data => {
  return request({
    url: "/login/updatePersonAllInfoByPartyId",
    method: 'post',
    data
  })
}
//根据手机号修改密码接口
export const appUpdatePasswordByPhone = data => {
  return request({
    url: "/appUpdatePasswordByPhone",
    method: 'post',
    data
  })
}

//更改会员级别接口
export const updatePersonMemberClasses = params =>{
	return request({
		url:"/login/updatePersonMemberClasses",
		method:'get',
		params
	})
}

//加入或退出协会的方法
export const applyJoinSpecialCommittee = params =>{
	return request({
		url:"/login/applyJoinSpecialCommittee",
		method:'get',
		params
	})
}

//找回密码方法
export const retrievePassword = params =>{
	return request({
		url:"/login/retrievePassword",
		method:'get',
		params
	})
}

//个人找回密码接口
export const changePersonPasswordByPhone = data => {
  return request({
    url: "/login/changePersonPasswordByPhone",
    method: 'post',
    data
  })
}

//企业找回密码接口
export const changeGroupPassword = data => {
  return request({
    url: "/login/changeGroupPassword",
    method: 'post',
    data
  })
}
