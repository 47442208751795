<template>
  <div class="page">
    <!-- 头部 -->
    <div class="header">
      <div>会员登录</div>
    </div>
    <!-- 内容 -->
    <div class="content_tab">
      <div
        :class="login_way == 0 ? 'sel_class' : 'no_class'"
        @click="changeLoginWay(0)"
      >
        企业账户
      </div>
      <div
        :class="login_way == 1 ? 'sel_class' : 'no_class'"
        @click="changeLoginWay(1)"
      >
        个人账户
      </div>
    </div>
    <!-- 公司-->
    <div v-if="login_way == 0">
      <div>
        <div class="login_content_form">
          <div>
            <img
              class="login_content_form_img"
              src="../assets/images/zhanghao.png"
            />
          </div>
          <div class="message_code">
            <input
              class="account_input"
              v-model="name"
              placeholder="请输入公司名称"
            />
          </div>
        </div>
        <div class="login_content_form">
          <div>
            <img
              class="login_content_form_img"
              src="../assets/images/mima.png"
            />
          </div>
          <div>
            <div class="message_code">
              <input
                type="password"
                class="account_input"
                v-model="password"
                placeholder="请输入密码"
              />
            </div>
          </div>
        </div>
        <div class="login_bottom">
          <div
            style="color: #a0a0a0; font-size: 16px; margin-left: 30px; flex: 3"
          >
            没有账号？<span style="color: #1e4a72" @click="register"
              >注册会员</span
            >
          </div>
          <div
            style="color: #1e4a72; font-size: 16px; flex: 1"
            @click="forget1"
          >
            找回密码
          </div>
        </div>
      </div>
      <div class="message_login" @click="groupLogin">登 录</div>
    </div>
    <div v-else-if="login_way == 1">
      <!-- 登录方式-->
      <div class="login_type">
        <div class="login_type_title">登录方式</div>
        <div class="login_type_input">
          <input
            type="radio"
            name="radiosType"
            value="0"
            v-model="login_type"
          /><label>验证码登录</label>
        </div>
        <div class="login_type_input">
          <input
            type="radio"
            name="radiosType"
            value="1"
            v-model="login_type"
          /><label>密码登录</label>
        </div>
      </div>
      <div v-if="login_type == '0'">
        <div>
          <div class="login_content_form">
            <div>
              <img
                class="login_content_form_img"
                src="../assets/images/zhanghao.png"
              />
            </div>
            <div class="login_type">
              <input
                class="message_input"
                v-model="mobile"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="login_content_form">
            <div>
              <img
                class="login_content_form_img"
                src="../assets/images/qr.png"
              />
            </div>
            <div>
              <div class="message_code">
                <input
                  class="message_code_input"
                  v-model="code"
                  placeholder="请输入验证码"
                />
                <div
                  class="message_code_button"
                  @click="smsCode"
                  v-if="btnShow"
                >
                  获取验证码
                </div>
                <div v-else class="message_code_text">
                  （<span>{{ count }}</span
                  >s）
                </div>
              </div>
            </div>
          </div>
          <div class="login_bottom">
            <div
              style="
                color: #a0a0a0;
                font-size: 16px;
                margin-left: 35px;
                line-height: 45px;
              "
            >
              没有账号？<span style="color: #1e4a72" @click="register"
                >注册会员</span
              >
            </div>
          </div>
        </div>
        <div class="message_login" @click="mobileCodeLogin">登 录</div>
      </div>
      <!-- 账户登录-->
      <div v-else-if="login_type == '1'">
        <div>
          <div class="login_content_form">
            <div>
              <img
                class="login_content_form_img"
                src="../assets/images/zhanghao.png"
              />
            </div>
            <div class="message_code">
              <input
                class="account_input"
                v-model="mobile"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="login_content_form">
            <div>
              <img
                class="login_content_form_img"
                src="../assets/images/mima.png"
              />
            </div>
            <div>
              <div class="message_code">
                <input
                  type="password"
                  class="account_input"
                  v-model="password"
                  placeholder="请输入密码"
                />
              </div>
            </div>
          </div>
          <div class="login_bottom">
            <div
              style="
                color: #a0a0a0;
                font-size: 16px;
                margin-left: 30px;
                flex: 3;
              "
            >
              没有账号？<span style="color: #1e4a72" @click="register"
                >注册会员</span
              >
            </div>
            <div
              style="color: #1e4a72; font-size: 16px; flex: 1"
              @click="forget2"
            >
              找回密码
            </div>
          </div>
        </div>

        <div class="message_login" @click="memberLogin">登 录</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  background-color: #fff;
  .content_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 5px;
    .sel_class {
      flex: 1;
      text-align: center;
      border-bottom: solid 2px #1e4a72;
      color: #1e4a72;
    }
    .no_class {
      flex: 1;
      text-align: center;
      color: #a3a8bd;
    }
  }
  .header {
    background-color: #1e4a72;
    width: 100%;
    height: 40px;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
    border-bottom: #b73b3b 3px solid;
  }
  .login_type {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    .login_type_title {
      margin-left: 30px;
      color: #3c3c3c;
    }
    .login_type_input {
      margin-left: 20px;
      color: #a0a0a0;
    }
  }
  .login_content_form {
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    height: 36px;
    .login_content_form_img {
      width: 28px;
      height: 28px;
      margin-left: 35px;
      margin-top: 5px;
    }
  }
  .login_bottom {
    display: flex;
    border-bottom: 3px solid #b73b3b;
    height: 45px;
    line-height: 45px;
  }
  .message_input {
    border: 0px;
    outline: none;
    height: 34px;
    margin-left: 45px;
    font-size: 16px;
  }
  .message_code {
    display: flex;
    .message_code_input {
      border: 0px;
      outline: none;
      height: 34px;
      width: 50%;
      margin-left: 45px;
      font-size: 16px;
    }
    .message_code_button {
      margin-top: 6px;
      font-size: 14px;
      width: 87px;
      height: 27px;
      background-color: #1e4a72;
      color: #fff;
      border-radius: 5px;
      /*margin-left:10px;*/
      text-align: center;
      line-height: 27px;
    }
    .message_code_text {
      background-color: #1e4a72;
      color: #fff;
      font-size: 18px;
      height: 34px;
      margin-top: 6px;
      width: 80px;
      height: 27px;
      border-radius: 5px;
      text-align: center;
      line-height: 27px;
    }
  }
  .message_login {
    width: 190px;
    height: 27px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 100px;
    margin-top: 15px;
    background-color: #1e4a72;
    color: #fff;
    text-align: center;
    line-height: 27px;
  }
  .account_input {
    border: 0px;
    outline: none;
    height: 34px;
    width: 100%;
    margin-left: 45px;
    /*border-radius:20px;*/
    /*background-color:#E5E5E5;*/
    font-size: 16px;
  }
  .account_button {
    width: 90%;
    height: 50px;
    font-size: 16px;
    border-radius: 25px;
    margin-left: 20px;
    margin-top: 100px;
    background-color: #0047ae;
    color: #fff;
    text-align: center;
    line-height: 50px;
  }

  /deep/ .el-checkbox__inner:hover {
    border-color: #ffd696 !important;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ffd696 !important;
    border-color: #ffd696 !important;
  }

  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4c4c4c;
    font-size: 0.075rem !important;
  }

  /deep/ .el-checkbox__label {
    font-size: 0.075rem !important;
  }
}
</style>
<script>
import check from "../utils/check";
import {
  sms,
  mobileCodeLogin,
  memberLogin,
  getOpenId,
  groupLogin,
} from "../api/user";
export default {
  name: "login",
  data() {
    return {
      password: "", //密码
      account: "", //账号
      checked: true,
      mobile: "", //手机号
      code: "", //验证码
      count: "", //倒计时
      btnShow: true, //按钮标记
      status: false,
      login_type: "0", //登录方式，0-手机号登录，1-账户登录
      wxCode: "", //微信code
      login_way: 0, //账户类型
      name: "", //公司名称
    };
  },
  mounted() {
    let openid = localStorage.getItem("openid");
    //alert("openid:"+openid);
    if(openid&&openid!=null){
    
    }else{
		let code=this.getUrlKey("code");//获取url参数code
		console.log("获取code",code)
		if(code){ //拿到code， code传递给后台接口换取opend
			this.getOpenId(code);
		}else{
			this.getCodeApi("123");
		}
    }
    this.shiFouDengLu();
  },
  methods: {
    shiFouDengLu() {
      var userInfo = localStorage.getItem("userInfo");
      console.log("获取登录参数", userInfo);
      if (userInfo != null && userInfo != "") {
        this.$router.push({ name: "Home" });
      }
    },
    //忘记密码
    forget1() {
      this.$router.push({ name: "forgetPwd", params: { memberType: 1 } });
    },
    forget2() {
      this.$router.push({ name: "forgetPwd", params: { memberType: 2 } });
    },
    //注册
    register() {
      this.$router.push({ name: "register" });
    },
    //发送验证码
    async smsCode() {
      if (!check.mobile(this.mobile)) {
        return this.$message.error("手机号格式不对！");
      }
      let { data } = await sms({
        mobile: this.mobile,
        status: "1",
        templateCode: "SMS_230655288",
      });
      if (data.code == 0) {
        if (data.data == "发送验证码间隔过短") {
          return this.$message.error("发送验证码间隔过短");
        }
        if (data.data == "") {
          return this.$message.error("手机号被禁止了");
        }
        this.countDown();
        this.$message.success("验证码已发送");
      } else {
        return this.$message.error("服务器繁忙");
      }
    },
    //倒计时
    countDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //手机号验证码登录
    async mobileCodeLogin() {
      if (!check.mobile(this.mobile)) {
        return this.$message.error("手机号格式不对");
      }
      if (this.code == null || "" == this.code) {
        return this.$message.error("请填写验证码");
      }
      let { data } = await mobileCodeLogin({
        mobile: this.mobile,
        code: this.code,
      });
      if (data.type == "success") {
        let userInfo = data.data;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.$router.push({ name: "Home" }); //跳转到主页
      } else {
        this.$message.error(data.content);
      }
    },
    //获取url 参数
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //获取code
    getCodeApi(state) {
      let urlNow = encodeURIComponent("http://www.tscsr.com/");
      let scope = "snsapi_base"; //snsapi_base //静默授权 用户无感知
      let appid = "wx9b285b6f35df0810";
      let url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        urlNow +
        "&response_type=code&scope=" +
        scope +
        "&state=" +
        state +
        "#wechat_redirect";
      window.location.href = url;
    },
    //个人手机号密码登录
    async memberLogin() {
      if (this.mobile == null || "" == this.mobile) {
        return this.$message.error("请填写账号");
      }
      if (this.password == null || "" == this.password) {
        return this.$message.error("请填写密码");
      }
      let { data } = await memberLogin({
        phone: this.mobile,
        password: this.password,
      });
      if (data.type == "success") {
        let userInfo = data.data;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("party_id", userInfo.party_ID);
        console.log("1111:" + localStorage.getItem("userInfo"));

        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.$router.push({ name: "Home" }); //跳转到主页
      } else {
        this.$message.error(data.content);
      }
    },
    //企业会员账户密码登录
    async groupLogin() {
      if (this.name == null || "" == this.name) {
        return this.$message.error("请填写公司名称");
      }
      if (this.password == null || "" == this.password) {
        return this.$message.error("请填写密码");
      }
      let { data } = await groupLogin({
        name: this.name,
        password: this.password,
      });
      if (data.type == "success") {
        let userInfo = data.data;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("party_id", userInfo.party_ID);
        console.log("1111:" + localStorage.getItem("userInfo"));
        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.$router.push({ name: "Home" }); //跳转到主页
      } else {
        this.$message.error(data.content);
      }
    },
    //改变登录方式
    changeLoginWay(e) {
      if (e == "0") {
        this.login_way = "0";
      } else if (e == "1") {
        this.login_way = "1";
      }
    },
    //获取用户openid
    async getOpenId(code) {
      let { data } = await getOpenId({
        code: code,
      });
      if (data.type == "success") {
        localStorage.setItem("openid", data.data);
      } else {
        this.$message.error(data.content);
      }
    },
  },
};
</script>
