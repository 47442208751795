class check {
	/**
	 * 验证电子邮箱格式
	 */
	email(value) {
		return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value);
	}

	/**
	 * 验证手机格式
	 */
	mobile(value) {
		return /^1[23456789]\d{9}$/.test(value)
	}

	/**
	 * 验证URL格式
	 */
	url(value) {
		return /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value)
	}

	/**
	 * 验证日期格式
	 */
	date(value) {
		return !/Invalid|NaN/.test(new Date(value).toString())
	}

	/**
	 * 验证ISO类型的日期格式
	 */
	dateISO(value) {
		return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(value)
	}

	/**
	 * 验证十进制数字
	 */
	number(value) {
		return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)
	}

	/**
	 * 验证整数
	 */
	digits(value) {
		return /^\d+$/.test(value)
	}

	/**
	 * 验证身份证号码
	 */
	idCard(value) {
		return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
			value)
	}

	/**
	 * 是否车牌号
	 */
	carNo(value) {
		// 新能源车牌
		const xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
		// 旧车牌
		const creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
		if (value.length === 7) {
			return creg.test(value);
		} else if (value.length === 8) {
			return xreg.test(value);
		} else {
			return false;
		}
	}

	/**
	 * 金额、价格，只允许2位小数
	 */
	amount(value) {
		//金额，只允许保留两位小数
		return /^[1-9]\d*(,\d{3})*(\.\d{1,2})?$|^0\.\d{1,2}$/.test(value);
	}

	/**
	 * 中文
	 */
	chinese(value) {
		let reg = /^[\u4e00-\u9fa5]+$/gi;
		return reg.test(value);
	}

	/**
	 * 只能输入字母
	 */
	letter(value) {
		return /^[a-zA-Z]*$/.test(value);
	}

	/**
	 * 只能是字母或者数字
	 */
	enOrNum(value) {
		//英文或者数字
		let reg = /^[0-9a-zA-Z]*$/g;
		return reg.test(value);
	}

	/**
	 * 验证是否包含某个值
	 */
	contains(value, param) {
		return value.indexOf(param) >= 0
	}

	/**
	 * 验证一个值范围[min, max]
	 */
	range(value, param) {
		return value >= param[0] && value <= param[1]
	}

	/**
	 * 验证一个长度范围[min, max]
	 */
	rangeLength(value, param) {
		return value.length >= param[0] && value.length <= param[1]
	}

	/**
	 * 是否固定电话
	 */
	landline(value) {
		let reg = /^\d{3,4}-\d{7,8}(-\d{3,4})?$/;
		return reg.test(value);
	}

	/**
	 * 判断是否为空
	 */
	empty(value) {
		switch (typeof value) {
			case 'undefined':
				return true;
			case 'string':
				if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
				break;
			case 'boolean':
				if (!value) return true;
				break;
			case 'number':
				if (0 === value || isNaN(value)) return true;
				break;
			case 'object':
				if (null === value || value.length === 0) return true;
				for (var i in value) {
					return false;
				}
				return true;
		}
		return false;
	}

	/**
	 * 是否json字符串
	 */
	jsonString(value) {
		if (typeof value == 'string') {
			try {
				var obj = JSON.parse(value);
				if (typeof obj == 'object' && obj) {
					return true;
				} else {
					return false;
				}
			} catch (e) {
				return false;
			}
		}
		return false;
	}


	/**
	 * 是否数组
	 */
	array(value) {
		if (typeof Array.isArray === "function") {
			return Array.isArray(value);
		} else {
			return Object.prototype.toString.call(value) === "[object Array]";
		}
	}

	/**
	 * 是否对象
	 */
	object(value) {
		return Object.prototype.toString.call(value) === '[object Object]';
	}

	/**
	 * 是否短信验证码
	 */
	phoneCode(value, len = 6) {
		return new RegExp(`^\\d{${len}}$`).test(value);
	}
	/*是否有空格*/
	spaceText(value) {
		let reg = /^\S*$/;
		return reg.test(value);
	}
	/*是否有特殊字符*/
	specialText(value) {
		let reg = /[\'.,:;*?~`!@#$%^&+-=)(<>{}]|\]|\[|\/|\\\|\"|\|/;
		return reg.test(value);
	}
	fileToBase64(file) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		return new Promise((resolve, reject) => {
			reader.onload = function (e) { //这里是一个异步，所以获取数据不好获取在实际项目中，就用new Promise解决
				if (this.result) {
					resolve(this.result)
				} else {
					reject("err")
				}

			}
		})
	}
}
export default new check;