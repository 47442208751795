<template>
  <div class="container">
    <!--标题-->
    <div class="title">会员信息</div>
    <!--头部-->
    <div class="header">
      <div class="header_title">
        <img class="header_title_img" src="../assets/images/logo.png">
        <div class="header_title_text">
          <div class="header_title_text1">唐山计算机应用学会</div>
          <div class="header_title_text2">会员证</div>
        </div>
      </div>
      <div class="header_content">
        <div class="header_content_top">
          <div class="header_content_top_cn">{{member_classes}}</div>
          <div style="display: flex;">
            <div class="header_content_top_cn_top">{{name}}</div>
            <div style="flex: 1"><img class="header_content_top_cn_img" src="../assets/images/qr.jpg"></div>
          </div>
          <div class="header_content_top_bottom">
            <div style="margin-left:15px;display: flex;">会员ID：<div style="width: 18px;height: 18px;background-color: #000;color: #fff;font-size: 14px;line-height: 20px;text-align: center;border-radius: 3px;">
              {{member_code_char}}</div>
              {{member_code}}</div>
            <div style="margin-left:30px;">{{take_effect_date}}至 {{invalid_date}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--信息展示-->
    <div>
      <div class="info">
        <div class="info_left" style="flex: 1;">
          会员类型：
        </div>
        <div class="info_middle" style="flex: 3;">
          {{member_type}}
        </div>
<!--        <div class="info_right" style="font-weight:bold;flex: 1;">更改类型</div>-->
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1;">
          会员级别：
        </div>
        <div class="info_middle" style="flex: 3;">
          {{member_classes}}
        </div>
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1;">
          会员ID：
        </div>
        <div class="info_middle" style="flex: 3;">
          {{code}}
        </div>
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1">
          有效期：
        </div>
        <div class="info_middle" style="flex: 3;display: flex">
          <div>{{take_effect_date}}至 {{invalid_date}}</div>
          <div v-if="is_sub" style="font-weight:bold;color: #1E4A72;" @click="renew">续交会费</div>
        </div>
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1">
          会员权益：
        </div>
        <div class="info_right" style="flex: 3;font-weight:bold;">查看权益</div>
      </div>
      <div v-if="member_type=='个人会员'&&member_classes=='专业会员'" class="bottom_info">
        <div style="flex: 1;">
          专委会：
        </div>
        <div style="margin-top: 30px;flex: 3;">
          <div style="display: flex">
            <div style="color: #a0a0a0;">智能制造专委会</div>
            <div style="margin-left: 30px;"><input type="checkbox" @click="shenqingjiaru('1')" :checked="zchecked"></input></div>
            <div v-if="zchecked" class="bottom_info_button">申请退出</div>
            <div v-else class="bottom_info_button">申请加入</div>
          </div>
          <div style="margin-top: 20px;display: flex">
            <div style="color: #a0a0a0;">人工智能专委会</div>
            <div style="margin-left: 30px;"><input type="checkbox" @click="shenqingjiaru('2')" :checked="rchecked"></input></div>
            <div v-if="rchecked" class="bottom_info_button">申请退出</div>
            <div v-else class="bottom_info_button">申请加入</div>
          </div>
          <div style="margin-top: 20px;display: flex">
            <div style="color: #a0a0a0;">信息安全专委会</div>
            <div style="margin-left: 30px;"><input type="checkbox" @click="shenqingjiaru('3')" :checked="xchecked"></input></div>
            <div v-if="xchecked" class="bottom_info_button">申请退出</div>
            <div v-else class="bottom_info_button">申请加入</div>
          </div>
        </div>
      </div>
      <div v-else-if="member_type=='企业会员'" class="info">
        <div class="info_left" style="flex: 1">
          会员管理：
        </div>
        <div class="info_right" style="flex: 3;font-weight:bold;" @click="toSubMember">查看详情</div>
      </div>
    </div>
    <div class="sign_out" @click="sign_out">退出登录</div>
  </div>
</template>
<script>
import {queryPersonInfoByPartyId,queryGroupInfoByPartyId,applyJoinSpecialCommittee } from "../api/user";
export default {
  name: "Home",
  data() {
    return {
      flag: false, //是否登录
      tabIdx: 0, //选中
      userObj: {}, //用户信息
      searchFlag: true, //搜索标记
      name: '',//姓名
      member_code: '',//会员码
      member_code_char: '',//会员标识
      member_type:'',//会员类型
      member_classes: '',//会员级别
      take_effect_date: '',//生效日期
      invalid_date: '',//有效日期
      mobile: '',//电话
      email: '',//邮箱
      cost: '200',//花费
      party_id: '',//人员id
      order_id:'',//订单id
      member_fee: 0,//订单金额
      zchecked:false,//
      rchecked:false,
      xchecked:false,
      classes:"",//级别
      code:"",//会员id
      special_list:[],//
      is_sub:false,//是否是公司子会员
    };
  },
  mounted() {
    console.log(localStorage.getItem('userInfo'));
    console.log(222);
    let user = JSON.parse(localStorage.getItem('userInfo'));
	this.party_id = user[0].party_ID;
    if(user[0].member_TYPE=="0"){
      this.getPersonInfoByPartyId(user[0].party_ID);
    }else{
      this.getGroupInfoByPartyId(user[0].party_ID);
    }
  },
  methods: {
    //选项
    tabSel(id) {
      this.tabIdx = id;
    },
	async shenqingjiaru(fid){
		let { data } = await applyJoinSpecialCommittee({
		  party_id: this.party_id,
		  fid: fid,
		});
		if (data.type == "success") {
		  this.$message({
		    message: "操作成功",
		    type: "success",
		  });
		  this.getPersonInfoByPartyId(this.party_id);
		  // this.$router.push({ name: "Home" });
		} else {
		  this.$message.error(data.content);
		}
	},
    //登录
    login() {
      this.$router.push({ name: "login" });
    },
    //注册
    register() {
      this.$router.push({ name: "register" });
    },
    //退出
    sign_out(){
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    //续交会费
    async renew(){
      this.$router.push({ name: "renew" });
    },
    //根据party_id获取用户信息
    async getPersonInfoByPartyId(party_id) {
      console.log(party_id);
      let { data } = await queryPersonInfoByPartyId({
        party_id: party_id
      });
      console.log(data);
      if (data.type == "success") {
        this.name =  data.data.name;//姓名
        this.member_code = data.data.member_CODE;//会员码
        this.invalid_date =  data.data.end_DATE;//有效期
		this.member_type = "个人会员";
        this.member_classes =  data.data.member_CLASSES==0?"专业会员":"学生会员";//会员类别
        this.take_effect_date = data.data.start_DATE!=null?data.data.start_DATE:"需交费";//生效日期
        this.mobile = data.data.mobile;
        this.email = data.data.email;
        this.member_code_char =   data.data.member_CODE_CHAR;
        this.member_code = data.data.member_CODE;
        this.code = this.member_code_char + this.member_code;
        this.is_sub = data.data.company_ID!=null&&data.data.company_ID!=""?false:true;
        this.special_list = data.data.special_LIST;
        if(this.special_list!=null&&this.special_list.length>0){
          for(let i=0;i<this.special_list.length;i++){
            if(this.special_list[i].code=="1"){
              this.zchecked = this.special_list[i].checked==null?false:true;
            }else if(this.special_list[i].code=="2"){
              this.rchecked = this.special_list[i].checked==null?false:true;
            }else if(this.special_list[i].code=="3"){
              this.xchecked = this.special_list[i].checked==null?false:true;
            }
          }
        }
      } else {
        this.$message.error(data.content);
      }
    },
    //根据party_id获取企业信息
    async getGroupInfoByPartyId(party_id) {
      console.log(party_id);
      let { data } = await queryGroupInfoByPartyId({
        party_id: party_id
      });
      console.log(data);
      if (data.type == "success") {
        this.name =  data.data.name;//姓名
        this.member_code = data.data.member_CODE;//会员码
        this.invalid_date =  data.data.end_DATE;//有效期
        if(data.data.member_CLASSES==0){//
          this.member_classes = "名誉理事长会员单位";
        }else if(data.data.member_CLASSES==1){
          this.member_classes = "理事会员单位";
        }else if(data.data.member_CLASSES==2){
          this.member_classes = "专业会员单位";
        }
        this.is_sub = true;
        this.member_type = "企业会员";
        this.take_effect_date = data.data.start_DATE!=null?data.data.start_DATE:"需交费";//生效日期
        this.mobile = data.data.mobile;
        this.email = data.data.email;
        this.member_code_char =   data.data.member_CODE_CHAR;
        this.member_code = data.data.member_CODE;
        this.code = this.member_code_char + this.member_code;
      } else {
        this.$message.error(data.content);
      }
    },
    //查询子会员详情
    toSubMember(){
      this.$router.push({ name: "managesubmember" });
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  font-family: Microsoft YaHei;
  font-size: 0.225rem;
  .title{
    height:40px;
    background-color: #1E4A72;
    color:#fff;
    font-size:20px;
    text-align: center;
    line-height:40px;
    border-bottom: #b73b3b 3px solid;
  }
  .header{
    /*width:95%;*/
    height:210px;
    background:#50B1DC;
    /*margin-top:10px;*/
    /*margin-left:10px;*/
    /*border-radius:10px;*/
    .header_title{
      display: flex;
      height:25%;
      .header_title_img{
        width:35px;
        height:35px;
        margin-left:10px;
        margin-top:10px;
      }
      .header_title_text{
        display: flex;
        margin-left:20px;
        margin-top:14px;
        .header_title_text1{
          font-size:18px;
          font-weight:bold;
        }
        .header_title_text2{
          font-size:16px;
          margin-left: 85px;
          font-weight: bold
        }
      }
    }
    .header_content{
      /*height:70%;*/
      background:#D0E8EA;
      .header_content_top{
        /*text-align: center;*/
        .header_content_top_cn{
          margin-left: 25px;
          padding-top: 20px;
          font-size:20px;
          /*font-weight:bolder;*/
          color: #ff0000;
        }
        .header_content_top_cn_top{
          flex: 5;
          text-align: center;
          /*margin-left:60px;*/
          padding-top:20px;
          font-size:18px;
        }
        .header_content_top_cn_img{
          width: 30px;
          height: 30px;
          /*margin-left: 45px;*/
          margin-top: 15px;
        }
        .header_content_top_bottom{
          display: flex;
          margin-top: 37px;
          font-size: 13px;
          color: #a0a0a0;
          padding-bottom: 9px;
          border-bottom: 3px solid #b73b3b;
        }
      }
    }
  }
  .function_button{
    display: flex;
    justify-content:center;
    margin-top:20px;
    text-align: center;
    align-items: center;
    color:#9A9899;
    .function_button_left{
      flex:1;
      border-right:1px solid #E5E5E5;
      border-bottom:1px solid #E5E5E5;
      margin-left:5px;
      font-size:12px;
    }
    .function_button_right{
      flex:1;
      border-bottom:1px solid #E5E5E5;
      margin-right:5px;
      font-size:12px;
    }
  }
  .info{
    display: flex;
    align-items: center;
    height:50px;
    line-height:50px;
    border-bottom:1px solid #E5E5E5;
    padding-left: 20px;
    .info_left{
      flex:1;
      /*margin-left:20px;*/
      align-items: center;
      font-size:16px;
      /*margin-top:3px;*/
      /*margin-left:3px;*/
    }
    .info_middle{
      flex:1;
      font-size:16px;
      /*margin-left:10px;*/
      color: #a0a0a0;
    }
    .info_right{
      flex:1;
      font-size:16px;
      /*margin-left:10px;*/
      color: #1E4A72;
    }
  }
  .sign_out{
    width:190px;
    height:27px;
    font-size:14px;
    border-radius:5px;
    margin-left:100px;
    margin-top:20px;
    background-color:#1E4A72;
    color:#fff;
    text-align: center;
    line-height:27px;
    margin-bottom: 30px;
  }
  .bottom_info{
    height: 160px;
    display: flex;
    border-bottom:1px solid #E5E5E5;
    font-size: 16px;
    padding-left: 20px;
    .bottom_info_button{
      margin-left: 15px;
      color: #1E4A72;
      font-weight:bold;
    }
  }
}
</style>
