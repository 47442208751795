import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "axios";
//引入element-ui的Message属性
import { Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//适配
import "./utils/flexible";
//把Message属性挂着到VUE原型上
Vue.prototype.$message = Message;
Vue.config.productionTip = false;
Vue.use(ElementUI);
//设置axios的请求头中包含token值
axios.interceptors.request.use(config => {
  config.headers.authorization = "APPCODE 阿里的ID";
  return config;
});

//把axios属性挂着到VUE原型上
Vue.prototype.$http = axios;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
