<template>
  <div class="page">
    <!-- 标题 -->
    <div class="title">添加招聘信息</div>
    <!-- 缴费信息 -->
    <div>
      <div class="form_item">
        <div style="flex:2;">企业名称</div>
        <div style="flex:5;">
          <input class="form_input"  v-model="name" placeholder="（必填）"/>
        </div>
      </div>
      <div class="form_item">
        <div style="flex:2;">招聘岗位</div>
        <div style="flex:5;">
          <input class="form_input"  v-model="name" placeholder="（必填）"/>
        </div>
      </div>
      <div class="form_item">
        <div style="flex:2;">薪资范围</div>
        <div style="flex:5;">
          <input class="form_input"  v-model="name" placeholder="（必填）"/>
        </div>
      </div>
      <div class="form_item">
        <div style="flex:2;">具体要求</div>
        <div style="flex:5;">
          <input class="form_input"  v-model="name" placeholder="（必填）"/>
        </div>
      </div>
      <div class="form_item">
        <div style="flex:2;">联系方式</div>
        <div style="flex:5;">
          <input class="form_input"  v-model="name" placeholder="（必填）"/>
        </div>
      </div>
    </div>
    <div class="change_button" @click="change">提 交</div>
    <div style="margin-top:269px;padding-left:20px;background-color: #656565;color: #fff;font-size: 12px;">
      <div style="padding-top: 15px;">电话：15830567234</div>
      <div style="padding-top: 10px;">邮箱：tscas@163.com</div>
      <div style="padding-top: 10px;padding-bottom: 20px;">办公地点：唐山市高新技术开发区元龙大厦19层</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  background-color:#fff;
  .title{
    height:40px;
    color:#1E4A72;
    font-size:20px;
    text-align: center;
    line-height:40px;
    border-bottom: #1E4A72 3px solid;
  }
  .form_item{
    display: flex;
    font-size:16px;
    padding-left:15px;
    padding-right:15px;
    justify-content: center;
    align-items: center;
    height:40px;
    font-weight: bold;
    color: #656565;
    border-bottom:1px solid #d2d2d2;
    .form_input{
      border:0px;
      outline:none;
      width:100%;
      height:34px;
      font-size:14px;
      color: #a0a0a0;
    }
    .form_code{
      background-color:#1E4A72;
      color:#fff;
      width:130px;
      height:30px;
      text-align: center;
      line-height:30px;
      border-radius:5px;
      font-size:14px;
    }
  }
  .change_button{
    width:190px;
    height:27px;
    font-size:14px;
    border-radius:5px;
    margin-left:100px;
    margin-top:20px;
    background-color:#1E4A72;
    color:#fff;
    text-align: center;
    line-height:27px;
    margin-bottom: 30px;
  }
}
</style>
<script>
import {
  queryPersonAllInfoByPartyId,
  updatePersonAllInfoByPartyId,
} from "../api/user";
export default {
  name: "recruit",
  components: {

  },
  data() {
    return {
      name: '', //姓名
      member: '', //会员号
      startDate: '', //生效日期
      endDate: '', //失效日期
      sex: 0, //性别
      bornDate: '', //出生日期
      email: '', //邮箱
      address: '', //通讯地址
      street: '', //通讯街道
      postal: '', //邮编
      phone: '', //手机号
      work_phone: '', //办公电话
      content: '', //备注
      education: '', //最高学历
      getDate: '', //获取时间
      company: '', //任职公司
      post: '', //职务
      work_year: '', //工作年限
      level: '', //职称
      company_type: '', //机构类型
      old_phone:'',//原手机号
    };
  },
   mounted() {
    if (this.$store.state.userInfo != null) {
      this.queryPersonAllInfoByPartyId(this.$store.state.userInfo[0].party_ID);
    }
  },
  methods: {
    //查询信息接口
    async queryPersonAllInfoByPartyId(party_id) {
      console.log(party_id);
      let { data } = await queryPersonAllInfoByPartyId({
        party_id: party_id,
      });
      if (data.type == "success") {
        this.name = data.data.name;
        this.member = data.data.member_CODE;
        this.startDate = data.data.take_EFFECT_DATE;
        this.endDate = data.data.invalid_DATE;
        this.sex = data.data.sex;
        this.bornDate = data.data.birthday;
        this.email = data.data.email;
        this.address = data.data.postal_ADDRESS;
        this.street = data.data.street_ADDRESS;
        this.postal = data.data.postal_CODE;
        this.phone = data.data.mobile;
        this.old_phone = data.data.mobile;
        this.work_phone = data.data.office_TELEPHONE;
        this.content = data.data.content;
        this.education = data.data.highest_EDUCATION;
        this.getDate =  data.data.get_TIME;
        this.company = data.data.employer;
        this.post = data.data.post;
        this.work_year = data.data.working_YEARS;
        this.level = data.data.professional_LEVEL;
        this.company_type = data.data.organization_TYPE;

      } else {
        this.$message.error(data.content);
      }
    },
    //确定
    async change() {
      if (this.bornDate==null||""==this.bornDate) {
        return this.$message.error("请输入出生日期");
      }
      if (this.email ==null||""==this.email) {
        return this.$message.error("请输入邮箱");
      }
      if (this.address ==null||""==this.address) {
        return this.$message.error("请输入通讯地址");
      }
      if (this.street ==null||""==this.street) {
        return this.$message.error("请输入街道地址");
      }
      if (this.postal==null||""==this.postal) {
        return this.$message.error("请输入邮编");
      }
      if (this.phone==null||""==this.phone) {
        return this.$message.error("请输入手机号");
      }
       if (this.education==null||""==this.education) {
        return this.$message.error("请输入最高学历");
      }
       if (this.getDate==null||""==this.getDate) {
        return this.$message.error("请输入获取时间");
      }
       if (this.company==null||""==this.company) {
        return this.$message.error("请输入任职公司");
      }
       if (this.post==null||""==this.post) {
        return this.$message.error("请输入现任职务");
      }
       if (this.work_year==null||""==this.work_year) {
        return this.$message.error("请输入工作年限");
      }
       if (this.level==null||""==this.level) {
        return this.$message.error("请输入职称级别");
      }
      let obj = {
          "birthday": this.bornDate,
          "content": this.content,
          "email": this.email,
          "employer": this.company,
          "get_TIME": this.getDate,
          "highest_EDUCATION": this.education,
          "mobile": this.phone,
          "office_TELEPHONE": this.work_phone,
          "organization_TYPE": this.company_type,
          "party_ID": this.$store.state.userInfo[0].party_ID,
          "post": this.post,
          "postal_ADDRESS": this.address,
          "postal_CODE": this.postal,
          "professional_LEVEL": this.level,
          "sex": this.sex,
          "street_ADDRESS": this.street,
          "working_YEARS": this.work_year,
          "old_phone":this.old_phone,
      };
      let str = JSON.stringify(obj);
      let { data } = await updatePersonAllInfoByPartyId(str);
      if (data.type == "success") {
        this.$message({
          message: "注册成功",
          type: "success",
        });
        this.$router.push({ name: "Home" });
      } else {
        this.$message.error(data.content);
      }

    },
    back(){
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
