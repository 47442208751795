import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')),//用户信息
  },
  mutations: {
    //保存用户信息
    setUserInfo(state, data) {
      state.userInfo = data.userInfo
      if (data.flag) {
        localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
      }
    }
  },
  actions: {},
  modules: {},
});
