<template>
  <div class="page">
    <!-- 标题 -->
    <div class="header">添加子会员</div>
    <!-- 缴费信息 -->
    <div>
      <div class="content_form">
        <div>
          <img class="content_form_img" src="../assets/images/member_code.png">
        </div>
        <div>
          <input class="account_input" v-model="member_code" placeholder="请输入会员号" @blur="queryPersonInfoByMemberCode">
        </div>
      </div>
      <div class="content_form">
        <div>
          <img class="content_form_img" src="../assets/images/zhanghao.png">
        </div>
        <div>
          <div>
            <span class="account_input">{{name}}</span>
          </div>
        </div>
      </div>
      <div class="content_form">
        <div>
          <img class="content_form_img" src="../assets/images/phone.png">
        </div>
        <div>
          <div>
            <span class="account_input">{{show_phone}}</span>
          </div>
        </div>
      </div>
      <div class="content_form" style="border-bottom: #b73b3b 3px solid;">
        <div>
          <img class="content_form_img" src="../assets/images/qr.png">
        </div>
        <div>
          <div class="message_code">
            <input class="message_code_input" v-model="code" placeholder="请输入验证码">
            <div class="message_code_button" @click="smsCode" v-if="btnShow">获取验证码</div>
            <div v-else class="message_code_text">
              （<span>{{ count }}</span
            >s）
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message_login" @click="submit">确 认</div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  background-color:#fff;
  .header{
    background-color:#1E4A72;
    width:100%;
    height:40px;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
    border-bottom: #b73b3b 3px solid;
  }
  .content_form{
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    height: 40px;
    .content_form_img{
      width: 28px;
      height: 28px;
      margin-left: 35px;
      margin-top: 5px;
    }
    .account_input{
      border:0px;
      outline:none;
      height:38px;
      width:100%;
      margin-left:45px;
      font-size:16px;
      color: #a0a0a0;
    }
  }
  .message_code{
    display:flex;
    .message_code_input{
      border:0px;
      outline:none;
      height:38px;
      width:50%;
      margin-left:45px;
      font-size:16px;
    }
    .message_code_button{
      margin-top: 6px;
      font-size:14px;
      width:87px;
      height:27px;
      background-color:#1E4A72;
      color:#fff;
      border-radius:5px;
      /*margin-left:10px;*/
      text-align: center;
      line-height:27px;
    }
    .message_code_text{
      background-color: #1E4A72;
      color:#fff;
      font-size: 18px;
      height: 34px;
      margin-top: 6px;
      width:80px;
      height:27px;
      border-radius:5px;
      text-align: center;
      line-height:27px;
    }
  }
  .message_login{
    width:190px;
    height:27px;
    font-size:14px;
    border-radius:5px;
    margin-left:100px;
    margin-top:15px;
    background-color:#1E4A72;
    color:#fff;
    text-align: center;
    line-height:27px;
  }
}
</style>
<script>
  import {
    queryPersonInfoByMemberCode, sms,
    addSubInfoByPartyId,
  } from "../api/user";
  import check from "../utils/check";
export default {
  name: "addSubMember",
  components: {

  },
  data() {
    return {
      name: '姓名', //姓名
      member_code: '', //会员号
      phone: '手机号', //手机号
      code: '', //验证码
      btnShow:true,//
      show_phone:'手机号',//展示手机号
      count: "", //倒计时
      company_id:"",//
      sub_id:""
    };
  },
   mounted() {
    if (localStorage.getItem('userInfo') != null) {
      let user = JSON.parse(localStorage.getItem('userInfo'));
      this.company_id = user[0].party_ID;
    }
  },
  methods: {
    //查询信息接口
    async smsCode(party_id) {
      if (!check.mobile(this.phone)) {
        return this.$message.error("手机号格式不对！");
      }
      let { data } = await sms({
        mobile: this.phone,
        status: "0",
        templateCode: 'SMS_230640397'
      });
      if (data.code == 0) {
        this.countDown();
        this.$message.success("验证码已发送");
      } else {
        this.$message.error(data.data);
      }
    },
    //倒计时
    countDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    back(){
      this.$router.push({ name: "Home" });
    },
    //确定
    async submit(){
      let { data } = await addSubInfoByPartyId({
        company_id:this.company_id,
        party_id:this.sub_id
      });
      if (data.type == "success") {
        this.$message.success("添加成功");
        this.$router.push({ name: "managesubmember" });
      } else {
        this.$message.error(data.content);
      }
    },
    //查询会员信息
    async queryPersonInfoByMemberCode(){
      if(this.member_code!=null&&this.member_code!=""){
        let { data } = await queryPersonInfoByMemberCode({
          member_code:this.member_code
        });
        if (data.type == "success") {
          this.name = data.data.name;
          this.phone = data.data.mobile;
          let reg = /^(\d{3})\d{4}(\d{4})$/;
          this.show_phone = data.data.mobile.replace(reg, "$1****$2");
          this.sub_id = data.data.party_ID;
        } else {
          this.$message.error(data.content);
          this.name = "姓名";
          this.show_phone = "电话";
        }
      }

    }

  },

};
</script>
