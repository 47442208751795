<template>
  <div class="page">
    <!-- 标题 -->
    <div class="title">
      会员注册
    </div>
    <!--注册表单-->
    <div class="form" v-if="step==1">
      <div class="form_item">
        <div style="flex:2;">会员分类</div>
        <div style="flex:2">
          <input type="radio" name="radiosType" value="0" v-model="member_type"/><label style="color: #a0a0a0;">个人会员</label>
        </div>
        <div style="flex:3">
          <input type="radio" name="radiosType" value="1" v-model="member_type"/><label style="color: #a0a0a0;">企业会员</label>
        </div>
      </div>
      <!--个人会员-->
      <div v-if = "member_type=='0'">
        <div class="form_item">
          <div style="flex:2;">会员类别</div>
          <div style="flex:2">
            <input type="radio" name="radiosClasses" value="0" v-model="member_classes"/><label style="color: #a0a0a0;">专业会员</label>
          </div>
          <div style="flex:3">
            <input type="radio" name="radiosClasses" value="1" v-model="member_classes"/><label style="color: #a0a0a0;">学生会员</label>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:2;">姓&#12288&#12288名</div>
          <div style="flex:5;">
            <input class="form_input"  v-model="name" placeholder="（必填）"/>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:2;">身份证号</div>
          <div style="flex:5;">
            <input class="form_input"  v-model="id_card" placeholder="（必填）"/>
          </div>
        </div>
        <!--个人专业会员-->
        <div v-if="member_type=='0'&&member_classes=='0'">
          <div class="form_item" >
            <div style="flex:2;">毕业学校</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="school" placeholder="（选填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">工作单位</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="employer" placeholder="（选填）"/>
            </div>
          </div>
        </div>
        <!--个人学生会员-->
        <div v-else-if="member_type=='0' && member_classes=='1'">
          <div class="form_item" >
            <div style="flex:2;">所在学校</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="school" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item" >
            <div style="flex:2;">所学专业</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="major" placeholder="（必填）"/>
            </div>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:1;">政治面貌</div>
          <div style="flex:1;margin-left: 20px;">
            <input type="radio" name="political" value="0" v-model="political"/><label style="color: #a0a0a0;">党员</label>
          </div>
          <div style="flex:1">
            <input type="radio" name="political" value="1" v-model="political"/><label style="color: #a0a0a0;">群众</label>
          </div>
          <div style="flex:1">
            <input type="radio" name="political" value="2" v-model="political"/><label style="color: #a0a0a0;">其他</label>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:2;">邮&#12288&#12288箱</div>
          <div style="flex:5;">
            <input class="form_input"  v-model="email" placeholder="（必填）"/>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:2;">设置密码</div>
          <div style="flex:5;">
            <input type="password" class="form_input"  v-model="password" placeholder="（6位数字和字母组合）"/>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:1;">确认密码</div>
          <div style="flex:2.3;">
            <input type="password" class="form_input"  v-model="checkPassword" placeholder="请确认密码"/>
          </div>
        </div>
        <div class="form_item">
          <div style="flex:2;">手机号码</div>
          <div style="flex:5;">
            <input class="form_input"  v-model="mobile" placeholder="（必填）"/>
          </div>
        </div>
        <div class="form_item" style="border-bottom: 3px solid #b73b3b;">
          <div style="flex:1;">验证码</div>
          <div style="flex:2.3;display: flex;align-items: center;">
            <input class="form_input"  v-model="code" placeholder="请输入验证码"/>
            <div class="form_code" @click="getCode" v-if="btnShow">获取验证码</div>
            <div class="code_text" v-else>
              （<span>{{ count }}</span
            >s）
            </div>
          </div>
        </div>
      </div>
      <!--企业会员-->
      <div v-else-if="member_type=='1'">
        <div class="form_item" style="height: 200px;">
          <div style="flex:1;">会员级别</div>
          <div style="flex: 2.5">
            <div style="margin-bottom: 10px">
              <input type="radio" name="radiosClasses" value="0" v-model="member_classes" @click="redioClick"/><label style="color: #a0a0a0;">名誉理事长会员单位</label>
              <div style="margin-left: 30px;margin-top: 10px;"><input type="checkbox" :checked="member_classes=='0'&&sub_member=='1'" @click="checkedOne('1')"/><label style="color: #a0a0a0;">单位子会员</label></div>
            </div>
            <div style="margin-bottom: 10px">
              <input type="radio" name="radiosClasses" value="1" v-model="member_classes"/><label style="color: #a0a0a0;">理事会员单位</label>
              <div style="margin-left: 30px;margin-top: 10px;"><input type="checkbox" :checked="member_classes=='1'&&sub_member=='2'" @click="checkedOne('2')"/><label style="color: #a0a0a0;">单位子会员</label></div>
            </div>
            <div>
              <input type="radio" name="radiosClasses" value="2" v-model="member_classes"/><label style="color: #a0a0a0;">专业会员单位</label>
              <div style="margin-left: 30px;margin-top: 10px;"><input type="checkbox" :checked="member_classes=='2'&&sub_member=='3'" @click="checkedOne('3')"/><label style="color: #a0a0a0;">单位子会员</label></div>
            </div>
          </div>
        </div>
        <div v-if="sub_member=='0'">
          <div class="form_item">
            <div style="flex:2;">公司名称</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="name" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">社会统一信用代码</div>
            <div style="flex:3;">
              <input class="form_input"  v-model="uniform_code" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">法人代表</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="corporation" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">公司电话</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="group_phone" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">公司邮箱</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="email" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">公司地址</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="address" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">联系人姓名</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="contact" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">联系人手机</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="mobile" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">设置密码</div>
            <div style="flex:5;">
              <input class="form_input" type="password"  v-model="password" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">确认密码</div>
            <div style="flex:5;">
              <input class="form_input" type="password"  v-model="checkPassword" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item" style="border-bottom: 3px solid #b73b3b;">
            <div style="flex:1;">验证码</div>
            <div style="flex:2.3;display: flex;align-items: center;">
              <input class="form_input"  v-model="code" placeholder="请输入验证码"/>
              <div class="form_code" @click="getCode" v-if="btnShow">获取验证码</div>
              <div class="form_code" v-else style="background-color: #eee;color:#0A60B3;">
                （<span>{{ count }}</span
              >s）
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="sub_member=='1'||sub_member=='2'||sub_member=='3'">
          <div class="form_item">
            <div style="flex:2;">公司名称</div>
            <div style="flex:5;">
              <select class="form_input"  @change="handleSelectChange($event)">
                <option value="NONE">请选择</option>
                <option v-for="(options,id) in selectClassData" :key="id" :value="options.party_ID">
                  {{options.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">联系人姓名</div>
            <div style="flex:5;">
              <span class="form_input">{{contact}}</span>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">联系人电话</div>
            <div style="flex:5;">
              <span class="form_input">{{contact_phone}}</span>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:1;">验证码</div>
            <div style="flex:2.3;display: flex;align-items: center;">
              <input class="form_input"  v-model="contact_code" placeholder="请输入验证码"/>
              <div class="form_code" @click="getContactCode" v-if="contact_btnShow">获取验证码</div>
              <div class="form_code" v-else style="background-color: #eee;color:#0A60B3;">
                （<span>{{ contact_count }}</span
              >s）
              </div>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">子会员姓名</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="name" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">身份证号</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="id_card" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:1;">政治面貌</div>
            <div style="flex:1;margin-left: 20px;">
              <input type="radio" name="political" value="0" v-model="political"/><label style="color: #a0a0a0;">党员</label>
            </div>
            <div style="flex:1">
              <input type="radio" name="political" value="1" v-model="political"/><label style="color: #a0a0a0;">群众</label>
            </div>
            <div style="flex:1">
              <input type="radio" name="political" value="2" v-model="political"/><label style="color: #a0a0a0;">其他</label>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">邮&#12288&#12288箱</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="email" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">子会员密码</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="password" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">确认密码</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="checkPassword" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item">
            <div style="flex:2;">子会员手机</div>
            <div style="flex:5;">
              <input class="form_input"  v-model="mobile" placeholder="（必填）"/>
            </div>
          </div>
          <div class="form_item" style="border-bottom: 3px solid #b73b3b;">
            <div style="flex:1;">验证码</div>
            <div style="flex:2.3;display: flex;align-items: center;">
              <input class="form_input"  v-model="code" placeholder="请输入验证码"/>
              <div class="form_code" @click="getCode" v-if="btnShow">获取验证码</div>
              <div class="form_code" v-else style="background-color: #eee;color:#0A60B3;">
                （<span>{{ count }}</span
              >s）
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_text">
        <div>确认信息无误后，提交注册，完成缴费后即成为会员</div>
      </div>
      <!-- 底部 -->
      <div class="bottom" @click="registerSub">
          提&#12288交
      </div>
    </div>
    <!--交费信息-->
    <div v-else-if="step==2" class="form">
      <div class="form_pay_content">
        <div style="flex:2;margin-left: 30px;">会员类型:</div>
        <div style="flex:3;color:#656565;">
          <label v-if="member_type==0">个人会员</label>
          <label v-else-if="member_type==1">企业会员</label>
        </div>
      </div>
      <div class="form_pay_content">
        <div style="flex:2;margin-left: 30px;">会员级别:</div>
        <div style="flex:3;color:#656565;">
          <label v-if="member_classes==0">专业会员</label>
          <label v-else-if="member_classes==1">学生会员</label>
        </div>
      </div>
      <div class="form_pay_content">
        <div style="flex:2;margin-left: 30px;">姓&#12288&#12288名:</div>
        <div style="flex:3;color:#656565;">
          <label>{{name}}</label>
        </div>
      </div>
      <div class="form_pay_content" style="border-bottom: 3px solid #b73b3b;">
        <div style="flex:2;margin-left: 30px;">需交会费:</div>
        <div style="flex:3;color:#656565;">
          <label>{{member_fee}}</label>
        </div>
      </div>
      <div style="text-align: center;margin-top: 20px;font-size: 14px;color:#656565;">
        支付成功后，即成为会员，享受相关权益
      </div>
      <div class="form_pay_button" @click="pay">支 付</div>
    </div>
    <!--完善信息-->
    <div v-else-if="step==3">
      <div class="form_pay_button" @click="login">去登陆</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  background-color: #E7E7E7;
  .title{
    height:40px;
    background-color: #1E4A72;
    color:#fff;
    font-size:20px;
    text-align: center;
    line-height:40px;
    border-bottom: #b73b3b 3px solid;
  }
  .form{
    background-color:#fff;
    height:100%;
    overflow-y:auto;
    .form_item{
      display: flex;
      font-size:16px;
      margin-left:15px;
      margin-right:15px;
      justify-content: center;
      align-items: center;
      height:40px;
      border-bottom:1px solid #d2d2d2;
      .form_input{
        border:0px;
        outline:none;
        width:100%;
        height:34px;
        font-size:14px;
        color: #a0a0a0;
      }
      .form_code{
        background-color:#1E4A72;
        color:#fff;
        width:130px;
        height:30px;
        text-align: center;
        line-height:30px;
        border-radius:5px;
        font-size:14px;
      }
      .code_text{
        background-color: #1E4A72;
        color:#fff;
        font-size: 18px;
        height: 34px;
        margin-top: 6px;
        width:80px;
        height:27px;
        border-radius:5px;
        text-align: center;
        line-height:27px;
      }
    }
    .bottom_text{
      font-size:12px;
      text-align: center;
      color:#656565;
      margin-top:20px;
      margin-bottom:20px;
    }
     .bottom{
       width:190px;
       height:27px;
       font-size:14px;
       border-radius:5px;
       margin-left:100px;
       margin-top:15px;
       margin-bottom: 30px;
       background-color:#1E4A72;
       color:#fff;
       text-align: center;
       line-height:27px;

    }
    .form_pay_title{
      font-size:18px;
      color:#0047AE;
      margin-left:20px;
      margin-top:10px;
    }
    .form_pay_content{
      display:flex;
      height:40px;
      line-height:40px;
      font-size:16px;
      border-bottom:solid 1px #EFEFEF;
    }
    .form_pay_button{
      width:190px;
      height:27px;
      font-size:14px;
      border-radius:5px;
      margin-left:100px;
      margin-top:20px;
      margin-bottom: 30px;
      background-color:#1E4A72;
      color:#fff;
      text-align: center;
      line-height:27px;
    }
  }

  /deep/ .el-checkbox__inner:hover {
    border-color: #ffd696 !important;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ffd696 !important;
    border-color: #ffd696 !important;
  }

  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4c4c4c;
    font-size: 0.075rem !important;
  }

  /deep/ .el-checkbox__label {
    font-size: 0.075rem !important;
  }
}
</style>
<script>
import check from "../utils/check";
import wx from "weixin-jsapi";
import { memberRegister,groupRegister,groupSubRegister,sms,getVcode,getGroupByClasses,getExpensesByClasses,updateStartInvalidDate,addOrderInfo,payMembershipFee,getOpenId } from "../api/user";
export default {
  name: "register",
  data() {
    return {
      password: "", //密码
      checkPassword: "", //确认密码
      name: "", //姓名
      id_card:"",//身份证号
      school:"",//毕业学校/所在学校
      major:"",//所学专业
      employer:"",//工作单位
      email:"",//邮箱
      checked: true, //协议
      member_type: 0,//会员种类
      member_classes: 0,//会员类别
      contact_phone:"",//联系人电话
      contact_phone1:"",//联系人电话1
      contact:"",//联系人
      address:"",//公司地址
      group_phone:"",//公司电话
      corporation:"",//法人
      uniform_code:"",//社会信用统一代码
      step: 1,//注册步骤，1-注册信息，2-交费，3-完善信息
      mobile: '',//手机号
      code: '',//验证码
      count: "", //倒计时
      contact_count:"",
      btnShow: true, //按钮标记
      contact_btnShow: true, //按钮标记
      member_fee: 0,//会费
      order_id: '',//订单id
      party_id: '',
      political:'0',//政治面貌
      sub_member:"0",//是否是子会员
      selectClassData:[],//公司选择
      group_name:"",
      contact_code:"",
      company_id:""
    };
  },
  mounted(){
	  let openid = localStorage.getItem("openid");
	   if(openid&&openid!=null){
	   }else{
		 //开发时需要注释掉
		 let code=this.getUrlKey("code");//获取url参数code
		 if(code){ //拿到code， code传递给后台接口换取opend
		  this.getOpenId(code);
		 }else{
		  this.getCodeApi("123");
		}
	   }
  },
  methods: {
    //登录
    login() {
      // this.addOrderInfo();
      this.$router.push({ name: "login" });
    },
    //注册提交
    async registerSub(){
      //个人会员
      if(this.member_type==0){
        if (this.name==null||""==this.name) {
          return this.$message.error("请输入姓名");
        }
        if (this.id_card==null||""==this.id_card) {
          return this.$message.error("请输入身份证号");
        }
        if(this.member_classes==1){//学生会员
          if (this.school ==null||""==this.school) {
            return this.$message.error("请输入所在学校");
          }
          if (this.major ==null||""==this.major) {
            return this.$message.error("请输入所学专业");
          }
        }
        if (this.email ==null||""==this.email) {
          return this.$message.error("请输入邮箱");
        }
        if (this.password ==null||""==this.password) {
          return this.$message.error("请输入密码");
        }
        if (this.checkPassword ==null||""==this.checkPassword) {
          return this.$message.error("请再次数据密码");
        }
        if (this.password != this.checkPassword) {
          return this.$message.error("两次密码不一致");
        }
        if (this.mobile==null||""==this.mobile) {
          return this.$message.error("请输入手机号");
        }
        let { data } = await getVcode({
          mobile: this.mobile,
          status: "0"
        });
        if (data.code == 0) {//判断验证码是否正确
          console.log(data.data)
          if(data.data!=this.code){
            return this.$message.error("验证码不正确！");
          }else{//跳转支付页面
            this.step = 2;
            this.getExpensesByClasses();
          }
        }else{
          this.$message.error("验证码不存在");
        }
      }else if(this.member_type==1){//企业会员
		console.log("this.sub_member",this.sub_member)
        if(this.sub_member==0){
          if (this.name==null||""==this.name) {
            return this.$message.error("请输入公司名称");
          }
          if (this.uniform_code==null||""==this.uniform_code) {
            return this.$message.error("请输入社会统一信用代码");
          }
          if (this.corporation==null||""==this.corporation) {
            return this.$message.error("请输入法人代表姓名");
          }
          if (this.group_phone==null||""==this.group_phone) {
            return this.$message.error("请输入公司电话");
          }
          if (this.address==null||""==this.address) {
            return this.$message.error("请输入公司地址");
          }
          if (this.email ==null||""==this.email) {
            return this.$message.error("请输入公司邮箱");
          }
          if (this.contact ==null||""==this.contact) {
            return this.$message.error("请输入联系人姓名");
          }
          if (this.password ==null||""==this.password) {
            return this.$message.error("请输入密码");
          }
          if (this.checkPassword ==null||""==this.checkPassword) {
            return this.$message.error("请再次数据密码");
          }
          if (this.password != this.checkPassword) {
            return this.$message.error("两次密码不一致");
          }
          if (this.mobile==null||""==this.mobile) {
            return this.$message.error("请输入联系人手机号");
          }
          let { data } = await getVcode({
            mobile: this.mobile,
            status: "0"
          });
          if (data.code == 0) {//判断验证码是否正确
            console.log(data.data)
            if(data.data!=this.code){
              return this.$message.error("验证码不正确！");
            }else{//跳转支付页面
              this.step = 2;
              this.getExpensesByClasses();
            }
          }else{
            this.$message.error("验证码不存在");
          }
        }else{
          if (this.group_name==null||""==this.group_name) {
            return this.$message.error("请选择公司");
          }
          let { data } = await getVcode({
            mobile: this.contact_phone1,
            status: "0"
          });
          if (data.code == 0) {//判断验证码是否正确
            console.log(data.data)
            if(data.data!=this.contact_code){
              return this.$message.error("联系人手机验证码不正确！");
            }
          }else{
            this.$message.error("联系人手机验证码不存在");
          }
		  if (this.id_card==null||""==this.id_card) {
		    return this.$message.error("请输入身份证号");
		  }
        }
          if (this.name==null||""==this.name) {
            return this.$message.error("请输入姓名");
          }
          
          if (this.email ==null||""==this.email) {
            return this.$message.error("请输入邮箱");
          }
          if (this.password ==null||""==this.password) {
            return this.$message.error("请输入密码");
          }
          if (this.checkPassword ==null||""==this.checkPassword) {
            return this.$message.error("请再次数据密码");
          }
          if (this.password != this.checkPassword) {
            return this.$message.error("两次密码不一致");
          }
          if (this.mobile==null||""==this.mobile) {
            return this.$message.error("请输入手机号");
          }
          let { data } = await getVcode({
            mobile: this.mobile,
            status: "0"
          });
          if (data.code == 0) {//判断验证码是否正确
            console.log(data.data)
            if(data.data!=this.code){
              return this.$message.error("验证码不正确！");
            }else{//跳转支付页面
              this.step = 2;
              this.getExpensesByClasses();
              // this.pay();
              // this.groupSubRegister();
            }
          }else{
            this.$message.error("验证码不存在");
          }
        }
      },
    //交费
    async pay(){
		// if(this.member_type==0){//个人会员
		// 	this.personRegister();
		// }else if(this.member_type==1&&this.sub_member==0){//企业注册
		// 	this.groupRegister()
		// }else if(this.member_type==1&&this.sub_member!=0){
		// 	this.groupSubRegister();
		// }
		var time = Date.parse(new Date()).toString();
		time = time.substr(0,10);
		this.order_id = "FEE"+time;
		console.log("查看OPENID",localStorage.getItem('openid'))
		let obj = {
			appid:'wx9b285b6f35df0810',
			mch_id:'1616439027',
			order_name: '会员交费',
			order_id: this.order_id,
			grandTotal: this.member_fee,
			trade_type: 'JSAPI',
			redirect_url: 'www.tscsr.com/#/register',
			notify_url: 'www.tscsr.com',
			openid: localStorage.getItem('openid'),
		};
        let str = JSON.stringify(obj);
		let { data } = await payMembershipFee(str);
		console.log("成功之前",data)
		if (data.code == "0") {
			// console.log("成功",data.data)
			// let payParams = JSON.parse(data.data);
			console.log("输出代码",data.map)
			wx.config({
			  debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
			  appId: data.map.appId, // 必填，公众号的唯一标识
			  timestamp: data.map.timeStamp, // 必填，生成签名的时间戳
			  nonceStr: data.map.nonceStr, // 必填，生成签名的随机串
			  signature: data.map.paySign, // 必填，签名
			  jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
			})
			wx.ready(() => {
				wx.checkJsApi({
					jsApiList: ['chooseWXPay'],
					success:function(res){
					  console.log("seccess")
					  console.log('hskdjskjk', res)
					},
					fail:function(res){
					  console.log("fail");
					  console.log(res)
					},
				})
			wx.chooseWXPay({
				timestamp: data.map.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
				nonceStr: data.map.nonceStr, // 支付签名随机串，不长于 32 位
				package: data.map.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
				signType: data.map.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
				paySign: data.map.paySign, // 支付签名
				complete: res => {
					if(res.errMsg == 'chooseWXPay:ok'){
						// 回调函数内容
						if(this.member_type==0){//个人会员
							this.personRegister();
						}else if(this.member_type==1&&this.sub_member==0){//企业注册
							this.groupRegister()
						}else if(this.member_type==1&&this.sub_member!=0){
							this.groupSubRegister();
						}
					}else{
						this.$message.error("支付失败");
					}
				}
              // success: function (res) {  // 支付成功后的回调函数
              //   if(this.member_type==0){//个人会员
              //     this.personRegister();
              //   }else if(this.member_type==1&&this.sub_member==0){//企业注册
              //     this.groupRegister()
              //   }else if(this.member_type==1&&this.sub_member!=0){
              //     this.groupSubRegister();
              //   }
              // },
              // fail: function (res) {
              //   alert("支付失败");
              //   alert(res.errMsg);
              // }
            })
        })
			wx.error(err => {
			  alert(err)
			})
		} else {
			this.$message.error(data.content);
		}
    },
    //个人会员注册
    async personRegister(){
      let obj = {
        MEMBER_CLASSES: this.member_classes,
        MEMBER_TYPE: this.member_type,
        MOBILE: this.mobile,
        NAME: this.name,
        EMAIL: this.email,
        PASSWORD: this.password,
        ID_CARD: this.id_card,
        STATUS: "2",
        SCHOOL: this.school,
        MAJOR: this.major,
        POLITIC: this.political,
        EMPLOYER: this.employer,
        AMOUNT: this.member_fee,
      };
      let str = JSON.stringify(obj);
      debugger;
      let { data } = await memberRegister(str);
      if (data.type == "success") {
        this.party_id = data.data[0].party_ID;
        this.$message({
          message: "注册成功",
          type: "success",
        });
        // this.login();
        this.step = 3;
      } else {
        this.$message.error(data.content);
      }

    },
    //企业会员注册
    async groupRegister(){
      let obj = {
        MEMBER_CLASSES: this.member_classes,
        MEMBER_TYPE: this.member_type,
        CORPORATION: this.corporation,
        GROUP_NAME: this.name,
        UNIFORM_CODE: this.uniform_code,
        EMAIL: this.email,
        GROUP_PHONE: this.group_phone,
        STATUS: "2",
        PASSWORD: this.password,
        ADDRESS: this.address,
        CONTACT_NAME: this.contact,
        CONTACT_PHONE: this.mobile,
        AMOUNT: this.member_fee,
      };
      let str = JSON.stringify(obj);
      debugger;
      let { data } = await groupRegister(str);
      if (data.type == "success") {
        // this.party_id = data.data[0].party_ID;
        this.$message({
          message: "注册成功",
          type: "success",
        });
        // this.login();
        this.step = 3;
      } else {
        this.$message.error(data.content);
      }

    },
    //企业子会员注册
    async groupSubRegister(){
      let obj = {
        MEMBER_CLASSES: this.member_classes,
        MEMBER_TYPE: this.member_type,
        NAME: this.name,
        EMAIL: this.email,
        MOBILE: this.mobile,
        STATUS: "2",
        PASSWORD: this.password,
        ID_CARD: this.id_card,
        POLITIC: this.political,
        COMPANY_ID: this.company_id,
      };
      let str = JSON.stringify(obj);
      debugger;
      let { data } = await groupSubRegister(str);
      if (data.type == "success") {
        this.party_id = data.data[0].party_ID;
        this.$message({
          message: "注册成功",
          type: "success",
        });
        // this.login();
        this.step = 3;
      } else {
        this.$message.error(data.content);
      }

    },
    //获取验证码
    async getCode(){
      if (!check.mobile(this.mobile)) {
        return this.$message.error("手机号格式不对！");
      }
      let { data } = await sms({
        mobile: this.mobile,
        status: "0",
        templateCode: 'SMS_230640397'
      });
      if (data.code == 0) {
        this.countDown();
        this.$message.success("验证码已发送");
      } else {
        this.$message.error(data.data);
      }
    },
    //获取联系人手机验证码
    async getContactCode(){
      console.log(this.contact_phone1)
      if (!check.mobile(this.contact_phone1)) {
        return this.$message.error("手机号格式不对！");
      }
      let { data } = await sms({
        mobile: this.contact_phone1,
        status: "0",
        templateCode: 'SMS_230640397'
      });
      if (data.code == 0) {
        this.contactCountDown();
        this.$message.success("验证码已发送");
      } else {
        this.$message.error(data.data);
      }
    },
    //倒计时
    countDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //倒计时
    contactCountDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.contact_count = TIME_COUNT;
        this.contact_btnShow = false;
        this.timer = setInterval(() => {
          if (this.contact_count > 0 && this.contact_count <= TIME_COUNT) {
            this.contact_count--;
          } else {
            this.contact_btnShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //获取url 参数
    getUrlKey(name){
      return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
    },
    //获取code
    getCodeApi(state){
     let urlNow=encodeURIComponent("http://www.tscsr.com/#/register");
     let scope='snsapi_base'; //snsapi_base //静默授权 用户无感知
     let appid='wx9b285b6f35df0810';
     let url="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+appid+"&redirect_uri="+urlNow+"&response_type=code&scope="+scope+"&state="+state+"#wechat_redirect";
     window.location.href=url;
    },
    //获取用户openid
    async getOpenId(code) {
      let { data } = await getOpenId({
        code: code
      });
      if (data.type == "success") {
        localStorage.setItem("openid", data.data);
      } else {
        this.$message.error(data.content);
      }
    },
    //更新订单信息
    async addOrderInfo(){
      let obj = {
        amount: this.member_fee,
        order_ID: this.order_id,
        order_NAME: '会员交费',
        party_ID: this.party_id,
        payment_METHOD: '0',
        status_ID: '1'
      };
      let str = JSON.stringify(obj);
      let { data } = await addOrderInfo(str);
      if (data.type == "success") {
        this.updateStartInvalidDate();
      }else{
        this.$message.error("创建订单失败");
      }
    },
    //更新起始日期和有效期
    async updateStartInvalidDate() {
      let { data } = await updateStartInvalidDate({
        party_id: this.party_id
      });
      if (data.type == "success") {
         console.log(data.content)
      } else {
        this.$message.error(data.content);
      }
    },
    checkedOne(val){
      console.log(val)
      if(this.sub_member==val){
        this.sub_member = '0';
      }else{
        this.sub_member = val;
      }
      this.getGroupByClasses();
    },
    redioClick(){
      this.sub_member = '0';
    },
    //子会员注册查询公司信息
    async getGroupByClasses(){
      let { data } = await getGroupByClasses({
        classes: this.member_classes
      });
      if (data.type == "success") {
        this.selectClassData = data.data
        // console.log(data.data)
      } else {
        this.$message.error(data.content);
      }
    },
    handleSelectChange(val){
      let id = val.target.value;
      for(let i=0;i<this.selectClassData.length;i++){
        if(id==this.selectClassData[i].party_ID){
          this.company_id = this.selectClassData[i].party_ID;
          this.contact = this.selectClassData[i].contact_NAME;
          this.group_name = this.selectClassData[i].name;
          let reg = /^(\d{3})\d{4}(\d{4})$/;
          this.contact_phone = this.selectClassData[i].contact_PHONE.replace(reg, "$1****$2");
          this.contact_phone1 = this.selectClassData[i].contact_PHONE;
        }
      }
    },
    //查询会员资费标准
    async getExpensesByClasses(){
      let type = "0";
      if(this.member_type==0){//个人
        if(this.member_classes==0){//专业
          type = "3";
          // this.member_fee = 100;
        }else if(this.member_classes==1){
          type = "4";
          // this.member_fee = 50;
        }
      }else if(this.member_type==1){//企业
        if(this.member_classes==0){//名誉理事
          // this.member_fee = 100;
          type = "0";
        }else if(this.member_classes==1){//理事
          // this.member_fee = 50;
          type = "1";
        }else if(this.member_classes==2){//专业
          type = "2";
        }
      }
      let { data } = await getExpensesByClasses({
        type: type
      });
      if (data.type == "success") {
        this.member_fee = data.data.price;
      } else {
        this.$message.error(data.content);
      }
    }
  }
};
</script>
