import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import login from "../views/login.vue"
import forgetPwd from "../views/forgetPwd.vue"
import register from "../views/register.vue"
import volunteerLogin from "../views/volunteerLogin.vue"
import renew from "../views/renew.vue"
import seek from "../views/seek.vue"
import recruit from "../views/recruit.vue"
import managesubmember from "../views/managesubmember.vue"
import addSubMember from "../views/addSubMember.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/forgetPwd/:memberType",
    name: "forgetPwd",
    component: forgetPwd,
  },
  {
    path: "/volunteerLogin",
    name: "volunteerLogin",
    component: volunteerLogin,
  },
  {
    path: "/renew",
    name: "renew",
    component: renew,
  },
  {
    path: "/seek",
    name: "seek",
    component: seek,
  },
  {
    path: "/recruit",
    name: "recruit",
    component: recruit,
  },
  {
    path: "/managesubmember",
    name: "managesubmember",
    component: managesubmember,
  },
  {
    path: "/addSubMember",
    name: "addSubMember",
    component: addSubMember,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
