<template>
  <div class="page">
    <div v-show="url==''">
      <!-- 头部 -->
      <div class="header">
        <div>
          志愿者证书中心
        </div>
      </div>
      <!-- 内容 -->
      <!-- 账户登录-->
      <div>
        <div>
          <input class="account_input" v-model="name" placeholder="姓名">
          <input class="account_input" v-model="code" placeholder="身份证号/手机号">
        </div>
        <div class="account_button" @click="queryVolunteer">查询</div>
      </div>
    </div>
    <div v-show="url!=''">
      <div style="margin-left: 10px;margin-top: 5px;font-size: 15px;flex: 1" @click="url = ''">返回</div>
      <div style="margin-top: 10px;"><img ref="imgDiv" style="width: 375px;height: 264px;" :src="url"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  .header{
    background-color:#CFDEF6;
    width:100%;
    height:30px;
    text-align: center;
    font-size: 20px;
  }
  .account_input{
    border:0px;
    outline:none;
    width:88%;
    height:40px;
    border-radius:20px;
    background-color:#E5E5E5;
    margin-left:15px;
    margin-top:20px;
    font-size:16px;
    padding-left:10px;
  }
  .account_button{
    width:90%;
    height:50px;
    font-size:16px;
    border-radius:25px;
    margin-left:20px;
    margin-top:100px;
    background-color:#0047AE;
    color:#fff;
    text-align: center;
    line-height:50px;
  }

  /deep/ .el-checkbox__inner:hover {
    border-color: #ffd696 !important;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ffd696 !important;
    border-color: #ffd696 !important;
  }

  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4c4c4c;
    font-size: 0.075rem !important;
  }

  /deep/ .el-checkbox__label {
    font-size: 0.075rem !important;
  }
}
</style>
<script>
import { getVolunteer } from "../api/user";
export default {
  name: "volunteerLogin",
  data() {
    return {
      name: "", //姓名
      code: "", //查询账号，身份证号或者手机号
      url: "",
      deg: 0
    };
  },
  mounted(){
    // rotate(){
    //   this.deg += 90;
    //   this.$refs.imgDiv.style.transform = `rotate(${this.deg}deg)`
    // }
  },
  methods: {
    //账户密码登录
    async queryVolunteer(){
      if (this.name==null||""==this.name) {
        return this.$message.error("请填写姓名");
      }
      let { data } = await getVolunteer({
        code: this.code,
        name: this.name
      });
      if (data.type == "success") {
        this.url = data.data.cer_url;
      } else {
        this.$message.error(data.content);
      }
    },
    // rotate(){
    //   this.deg += 90;
    //   this.$refs.imgDiv.style.transform = `rotate(${this.deg}deg)`
    // }
  },
};
</script>
