<template>
  <div class="page">
    <!-- 标题 -->
    <div class="title">子会员管理</div>
    <div class="header">子会员明细</div>
    <!-- 会员信息 -->
    <div style="margin-top: 20px;">
      <el-table :data="tableData" :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }" style="font-size: 12px;">
        <el-table-column
                label="序号"
                width="50"
                >
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
                prop="member_CODE"
                label="会员ID"
                width="100"
                >
        </el-table-column>
        <el-table-column
                prop="name"
                label="姓名"
                width="60"
                >
        </el-table-column>
        <el-table-column
                prop="mobile"
                label="手机号"
                width="100"
                >
        </el-table-column>
        <el-table-column
                label="操作"
                width="60"
                >
          <template slot-scope="scope">
            <el-button
                    size="mini"
                    type="text"
                    @click="handleMatDelete(scope.row)"
                    style="color: #1E4A72;"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px;margin-left: 20px;font-size: 14px;color: #1E4A72;" @click="addSubMember">添加子会员</div>
    <div v-show="popup">
      <!--这里是要展示的内容层-->
      <div class="popup">
        <div class="popup_text">
          您是否确定要将子会员<span style="color: #1E4A72;font-weight: bold">{{sub_name}}</span>从本公司子会员体系中移除？移除后该子会员将不再享受公司子会员相关权益。
        </div>
        <div class="popup_button">
          <div style="flex: 1;border-right:solid 1px;" @click="deleteSubInfoByPartyId">确定</div>
          <div style="flex: 1;" @click="backTable">返回</div>
        </div>
      </div>
      <!--这里是半透明背景层-->
      <div class="over"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  /*background-color:#E8E8E8;*/
  .title{
    height:40px;
    background-color: #1E4A72;
    color:#fff;
    font-size:20px;
    text-align: center;
    line-height:40px;
    border-bottom: #b73b3b 3px solid;
  }
  .header{
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
  }
  .info{
    display: flex;
    align-items: center;
    height:50px;
    line-height:50px;
    border-bottom:1px solid #E5E5E5;
    padding-left: 20px;
    .info_left{
      flex:1;
      align-items: center;
      font-size:16px;
    }
    .info_middle{
      flex:1;
      font-size:16px;
      /*margin-left:10px;*/
      color: #a0a0a0;
    }
    .info_right{
      flex:1;
      font-size:16px;
      /*margin-left:10px;*/
      color: #1E4A72;
    }
  }
  .change_button{
    width:190px;
    height:27px;
    font-size:14px;
    border-radius:5px;
    margin-left:100px;
    margin-top:20px;
    background-color:#1E4A72;
    color:#fff;
    text-align: center;
    line-height:27px;
    margin-bottom: 30px;
  }
  .over{
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.4;//透明度为70%
    filter: alpha(opacity=70);
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #111111;
  }
  .popup{
    position: fixed;
    height: 195px;
    width: 240px;
    background-color: #fff;
    border-radius: 0.25rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    .popup_text{
      font-size: 16px;
      color: #656565;
      margin: 20px 10px;
    }
    .popup_button{
      display: flex;
      margin-top: 60px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      background-color: #1E4A72;
      height: 31px;
      line-height: 31px;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}
</style>
<script>
import {
  querySubInfoByPartyId,
  deleteSubInfoByPartyId
} from "../api/user";
export default {
  name: "managesubmember",
  components: {

  },
  data() {
    return {
      tableData:[],
      popup:false,
      sub_id:"",//子会员id
      sub_name:"",//子会员名称
      company_id:"",//公司id
    };
  },
   mounted() {
    if (localStorage.getItem('userInfo') != null) {
      let user = JSON.parse(localStorage.getItem('userInfo'));
      this.company_id = user[0].party_ID;
      this.querySubInfoByPartyId(user[0].party_ID);
    }
  },
  methods: {
    //查询子会员信息接口
    async querySubInfoByPartyId(party_id) {
      console.log(party_id);
      let { data } = await querySubInfoByPartyId({
        party_id: party_id,
      });
      if (data.type == "success") {
        this.tableData = data.data;
      } else {
        this.$message.error(data.content);
      }
    },
    back(){
      this.$router.push({ name: "Home" });
    },
    //删除
    async handleMatDelete(row){
      this.popup = true;
      this.sub_id = row.party_ID;
      this.sub_name = row.name;
      // console.log(this.popup)
    },
    backTable(){
      this.popup = false;
    },
    //添加子会员
    addSubMember(){
      this.$router.push({ name: "addSubMember" });
    },
    //确定删除
    async deleteSubInfoByPartyId(){
      let { data } = await deleteSubInfoByPartyId({
        company_id:this.company_id,
        party_id: this.sub_id
      });
      if (data.type == "success") {
        this.popup = false;
        this.querySubInfoByPartyId(this.company_id);
      } else {
        this.$message.error(data.content);
      }
    }
  },
};
</script>
