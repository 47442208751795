<template>
  <div class="page">
    <!-- 头部 -->
    <div class="header">找回密码</div>
    <div>
      <div class="content_form">
        <div>
          <img class="content_form_img" src="../assets/images/phone.png" />
        </div>
        <div>
          <div class="message_code">
            <input
              class="message_code_input"
              v-model="mobile"
              placeholder="手机号"
            />
          </div>
        </div>
      </div>
      <div class="content_form">
        <div>
          <img class="content_form_img" src="../assets/images/qr.png" />
        </div>
        <div>
          <div class="message_code">
            <input
              class="message_code_input"
              v-model="vcode"
              placeholder="请输入验证码"
            />
            <div class="message_code_button" @click="smsCode" v-if="btnShow">
              获取验证码
            </div>
            <div v-else class="message_code_text">
              （<span>{{ count }}</span
              >s）
            </div>
          </div>
        </div>
      </div>
      <div class="content_form">
        <div>
          <img class="content_form_img" src="../assets/images/mima.png" />
        </div>
        <div>
          <div class="message_code">
            <input
			  type="password"
              class="message_code_input"
              v-model="password1"
              placeholder="请输入新密码"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content_form" style="border-bottom: #b73b3b 3px solid">
      <div>
        <img class="content_form_img" src="../assets/images/mima.png" />
      </div>
      <div class="message_code">
        <input
		  type="password"
          class="message_code_input"
          v-model="password2"
          placeholder="请确认密码"
        />
      </div>
    </div>
    <div class="message_login" @click="querentijiao">提 交</div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  background-color: #fff;
  .header {
    background-color: #1e4a72;
    width: 100%;
    height: 40px;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 40px;
    border-bottom: #b73b3b 3px solid;
  }
  .content_form {
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    height: 40px;
    .content_form_img {
      width: 28px;
      height: 28px;
      margin-left: 35px;
      margin-top: 5px;
    }
    .account_input {
      border: 0px;
      outline: none;
      height: 38px;
      width: 100%;
      margin-left: 45px;
      font-size: 16px;
      color: #a0a0a0;
    }
  }
  .message_code {
    display: flex;
    .message_code_input {
      border: 0px;
      outline: none;
      height: 38px;
      width: 50%;
      margin-left: 45px;
      font-size: 16px;
    }
    .message_code_button {
      margin-top: 6px;
      font-size: 14px;
      width: 87px;
      height: 27px;
      background-color: #1e4a72;
      color: #fff;
      border-radius: 5px;
      /*margin-left:10px;*/
      text-align: center;
      line-height: 27px;
    }
    .message_code_text {
      background-color: #1e4a72;
      color: #fff;
      font-size: 18px;
      height: 34px;
      margin-top: 6px;
      width: 80px;
      height: 27px;
      border-radius: 5px;
      text-align: center;
      line-height: 27px;
    }
  }
  .message_login {
    width: 190px;
    height: 27px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 100px;
    margin-top: 15px;
    background-color: #1e4a72;
    color: #fff;
    text-align: center;
    line-height: 27px;
  }
}
</style>
<script>
import check from "../utils/check";
import { sms, appUpdatePasswordByPhone,getVcode,changePersonPasswordByPhone,changeGroupPassword } from "../api/user";
export default {
  name: "forgetPwd",
  data() {
    return {
      flag: 0,
      mobile: "", //手机号
      vcode: "", //验证码
      count: "", //倒计时
      btnShow: true, //按钮标记
      password1: "", //密码1
      password2: "", //密码2
      timer: "",
	  name:"",//登录名称
    };
  },
  mounted() {
  	
  },
  methods: {
    //登录
    login() {
      this.$router.push({ name: "login" });
    },
    //倒计时
    countDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //发送验证码
    async smsCode() {
      if (!check.mobile(this.mobile)) {
        return this.$message.error("手机号格式不对！");
      }
      alert(this.mobile);
      let { data: res } = await sms({
        mobile: this.mobile,
        status: "0",
        templateCode: "SMS_230655288",
      });
      if (res.code == 0) {
        if (res.data == "发送验证码间隔过短") {
          return this.$message.error("发送验证码间隔过短");
        }
        if (res.data == "") {
          return this.$message.error("手机号被禁止了");
        }
        this.countDown();
        this.$message("验证码已发送");
      } else {
        return this.$message.error("服务器繁忙");
      }
    },
	async querentijiao(){
		if (this.mobile==null||""==this.mobile) {
		  return this.$message.error("请输入联系人手机号");
		}
		if (this.password1 ==null||""==this.password1) {
		  return this.$message.error("请输入密码");
		}
		if (this.password2 ==null||""==this.password2) {
		  return this.$message.error("请再次数据密码");
		}
		if (this.password1 != this.password2) {
		  return this.$message.error("两次密码不一致");
		}  
		let { data } = await getVcode({
		  mobile: this.mobile,
		  status: "0"
		});    
		if (data.code == 0) {//判断验证码是否正确
		  console.log(data.data)
		  if(data.data!=this.vcode){
		    return this.$message.error("验证码不正确！");
		  }else{
			  //修改密码传递参数   1 为公司客户找回密码、 2 为个人客户找回密码
			  let memberType = this.$route.params.memberType;
			  if(memberType == 1){
				let obj = {
								name: this.name,
								password: this.password1,
				};
				let str = JSON.stringify(obj);
				let { data: res } = await changeGroupPassword(str);
				if (res.code == 0) {
								this.flag = 2;
								return this.$message.success("修改成功");
								this.$router.push({ name: "login" });
				} else{
					return this.$message.error(res.content);
				}
			  }else if(memberType == 2){
			  	let obj = {
			  					mobile: this.mobile,
			  					password: this.password1,
			  	};
			  	let str = JSON.stringify(obj);
			  	let { data: res } = await changePersonPasswordByPhone(str);
			  	if (res.code == 0) {
			  					this.flag = 2;
			  					return this.$message.success("修改成功");
			  					this.$router.push({ name: "login" });
			  	} else{
					return this.$message.error(res.content);
				}
			  }else {
			  	return this.$message.error("请通过正确的方法进入修改密码界面");
			  }
		  }
		}else{
		  this.$message.error("验证码已失效或不存在！请重新获取验证码。");
		}
	},
    //根据手机号修改密码接口
    async appUpdatePasswordByPhone() {
      if (this.password1 != this.password2) {
        return this.$message.error("两次密码不一致");
      }
      let obj = {
        mobile: this.mobile,
        password: this.password1,
      };
      let str = JSON.stringify(obj);
      let { data: res } = await appUpdatePasswordByPhone(str);
      if (res.code == 0) {
        this.flag = 2;
      } else {
        return this.$message.error("服务器繁忙");
      }
    },
  },
};
</script>
