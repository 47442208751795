<template>
  <div class="page">
    <!-- 标题 -->
    <div class="title">续交会费</div>
    <!-- 缴费信息 -->
    <div>
      <div class="info">
        <div class="info_left" style="flex: 1">会员类型：</div>
        <div class="info_middle" style="flex: 2; display: flex">
          <div>{{ member_type }}</div>
        </div>
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1">会员级别：</div>
        <div class="info_middle" style="flex: 2; display: flex">
          <div style="flex: 2">{{ member_classes }}</div>
          <div
            style="font-weight: bold; color: #1e4a72; flex: 1"
            @click="changeClasses"
          >
            更改级别
          </div>
        </div>
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1">会员ID：</div>
        <div class="info_middle" style="flex: 2; display: flex">
          <div>{{ member }}</div>
        </div>
      </div>
      <div class="info">
        <div class="info_left" style="flex: 1">会员姓名：</div>
        <div class="info_middle" style="flex: 2; display: flex">
          <div>{{ name }}</div>
        </div>
      </div>
      <div class="info" style="border-bottom: #b73b3b 3px solid">
        <div class="info_left" style="flex: 1">续费有效期：</div>
        <div class="info_middle" style="flex: 2; display: flex">
          <div>{{ startDate }}至{{ endDate }}</div>
        </div>
      </div>
    </div>
    <div class="change_button" @click="submit">确定</div>
    <div v-show="popup">
      <!--这里是要展示的内容层-->
      <div class="popup">
        <div class="popup_text">
          <div v-if="member_type == '企业会员'">
            <div
              style="
                flex: 1;
                margin-left: 20px;
                font-size: 18px;
                margin-top: 20px;
              "
            >
              <input
                type="radio"
                s
                name="classes"
                value="0"
                v-model="classes"
              /><label style="color: #a0a0a0">名誉理事长会员单位</label>
            </div>
            <div
              style="
                flex: 1;
                margin-left: 20px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              <input
                type="radio"
                name="classes"
                value="1"
                v-model="classes"
              /><label style="color: #a0a0a0">理事会员单位</label>
            </div>
            <div
              style="
                flex: 1;
                margin-left: 20px;
                font-size: 18px;
                margin-top: 10px;
              "
            >
              <input
                type="radio"
                name="classes"
                value="2"
                v-model="classes"
              /><label style="color: #a0a0a0">专业会员单位</label>
            </div>
          </div>
          <div v-else>
            <div
              style="
                flex: 1;
                margin-left: 20px;
                font-size: 20px;
                margin-top: 30px;
              "
            >
              <input
                type="radio"
                name="classes"
                value="0"
                v-model="classes"
              /><label style="color: #a0a0a0">专业会员</label>
            </div>
            <div
              style="
                flex: 1;
                font-size: 20px;
                margin-left: 20px;
                margin-top: 30px;
              "
            >
              <input
                type="radio"
                name="classes"
                value="1"
                v-model="classes"
              /><label style="color: #a0a0a0">学生会员</label>
            </div>
          </div>
        </div>
        <div class="popup_button">
          <div style="flex: 1; border-right: solid 1px" @click="changeSubmit">
            确定
          </div>
          <div style="flex: 1" @click="backTable">返回</div>
        </div>
      </div>
      <!--这里是半透明背景层-->
      <div class="over"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  width: 100%;
  /*background-color:#E8E8E8;*/
  .title {
    height: 40px;
    background-color: #1e4a72;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    border-bottom: #b73b3b 3px solid;
  }
  .info {
    display: flex;
    align-items: center;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 20px;
    .info_left {
      flex: 1;
      align-items: center;
      font-size: 16px;
    }
    .info_middle {
      flex: 1;
      font-size: 16px;
      /*margin-left:10px;*/
      color: #a0a0a0;
    }
    .info_right {
      flex: 1;
      font-size: 16px;
      /*margin-left:10px;*/
      color: #1e4a72;
    }
  }
  .change_button {
    width: 190px;
    height: 27px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 100px;
    margin-top: 20px;
    background-color: #1e4a72;
    color: #fff;
    text-align: center;
    line-height: 27px;
    margin-bottom: 30px;
  }
  .over {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.4; //透明度为70%
    filter: alpha(opacity=70);
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #111111;
  }
  .popup {
    position: fixed;
    height: 195px;
    width: 240px;
    background-color: #fff;
    border-radius: 0.25rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    .popup_text {
      font-size: 16px;
      color: #656565;
      /*margin: 20px 10px;*/
    }
    .popup_button {
      display: flex;
      margin-top: 52px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      background-color: #1e4a72;
      height: 31px;
      line-height: 31px;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}
</style>
<script>
import {
  addOrderInfo,
  payMembershipFee,
  queryGroupInfoByPartyId,
  queryPersonInfoByPartyId,
  updateInvalidDate,
  updatePersonMemberClasses,
  getExpensesByClasses,
} from "../api/user";
import wx from "weixin-jsapi";
export default {
  name: "renew",
  components: {},
  data() {
    return {
      name: "", //姓名
      member: "", //会员号
      startDate: "", //生效日期
      endDate: "", //失效日期
      member_classes: "", //级别
      member_type: "", //会员类型
      member_ty: "",
      member_fee: 0,
      party_id: "",
      popup: false, //是否展示更改级别弹窗
      classes: "", //会员级别
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      this.party_id = user[0].party_ID;
      if (user[0].member_TYPE == 0) {
        this.getPersonInfoByPartyId(user[0].party_ID);
      } else {
        this.getGroupInfoByPartyId(user[0].party_ID);
      }
    }
  },
  methods: {
    //根据party_id获取用户信息
    async getPersonInfoByPartyId(party_id) {
      console.log(party_id);
      let { data } = await queryPersonInfoByPartyId({
        party_id: party_id,
      });
      console.log(data);
      if (data.type == "success") {
        let now_time = this.showtime();
        this.name = data.data.name; //姓名
        this.invalid_date = data.data.end_DATE; //有效期
        this.member_type = "个人会员";
        this.classes = data.data.member_CLASSES;
        this.member_classes =
          data.data.member_CLASSES == 0 ? "专业会员" : "学生会员"; //会员类别
        if (data.data.end_DATE >= now_time) {
          //还在原有效期内
          let last_time = this.lastYear(data.data.end_DATE, 1);
          this.endDate = last_time;
          this.startDate = now_time; //data.data.end_DATE;
        } else {
          let last_time = this.lastYear(now_time, 1);
          this.endDate = last_time;
          this.startDate = now_time;
        }
        this.member = data.data.member_CODE_CHAR + data.data.member_CODE;
      } else {
        this.$message.error(data.content);
      }
      this.getExpensesByClasses();
    },
    //根据party_id获取企业信息
    async getGroupInfoByPartyId(party_id) {
      console.log(party_id);
      let { data } = await queryGroupInfoByPartyId({
        party_id: party_id,
      });
      console.log(data);
      if (data.type == "success") {
        let now_time = this.showtime();
        this.name = data.data.name; //姓名
        this.invalid_date = data.data.end_DATE; //有效期
        this.classes = data.data.member_CLASSES;
        if (data.data.member_CLASSES == 0) {
          //
          this.member_classes = "名誉理事长会员单位";
        } else if (data.data.member_CLASSES == 1) {
          this.member_classes = "理事会员单位";
        } else if (data.data.member_CLASSES == 2) {
          this.member_classes = "专业会员单位";
        }
        this.member_type = "企业会员";
        if (data.data.end_DATE >= now_time) {
          //还在原有效期内
          let last_time = this.lastYear(data.data.end_DATE, 1);
          this.endDate = last_time;
          this.startDate = now_time; //data.data.end_DATE;
        } else {
          let last_time = this.lastYear(now_time, 1);
          this.endDate = last_time;
          this.startDate = now_time;
        }
        this.member = data.data.member_CODE_CHAR + data.data.member_CODE;
      } else {
        this.$message.error(data.content);
      }
      this.getExpensesByClasses();
    },
    showtime() {
      var nowdate = new Date();
      var year = nowdate.getFullYear(),
        month = nowdate.getMonth() + 1,
        date = nowdate.getDate(),
        day = nowdate.getDay(),
        week = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ],
        h = nowdate.getHours(),
        m = nowdate.getMinutes(),
        s = nowdate.getSeconds();
      if (month < 10) {
        month = "0" + month;
      }
      if (date < 10) {
        date = "0" + date;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (s < 10) {
        s = "0" + s;
      }
      return year + "-" + month + "-" + date;
    },
    lastYear(time, index) {
      let nowdate = new Date(time);
      let dateTime = nowdate.getFullYear(); /* 获取现在的年份 */
      let month = nowdate.getMonth() + 1,
        date = nowdate.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (date < 10) {
        date = "0" + date;
      }
      dateTime = new Date(
        new Date().setFullYear(dateTime + index)
      ).getFullYear();
      return dateTime + "-" + month + "-" + date;
    },
    //查询会员资费标准
    async getExpensesByClasses() {
      let type = "0";
      if (this.member_type == "个人会员") {
        if (this.member_classes == "学生会员") {
          type = "4";
        } else if (this.member_classes == "专业会员") {
          type = "3";
        }
      } else if (this.member_type == "企业会员") {
        if (this.member_classes == "名誉理事长会员单位") {
          type = "0";
        } else if (this.member_classes == "理事会员单位") {
          type = "1";
        } else if (this.member_classes == "专业会员单位") {
          type = "2";
        }
      }
      let { data } = await getExpensesByClasses({
        type: type,
      });
      if (data.type == "success") {
        this.member_fee = data.data.price;
      } else {
        this.$message.error(data.content);
      }
    },
    //确定
    async submit() {
      // this.addOrderInfo();
      var time = Date.parse(new Date()).toString();
      time = time.substr(0, 10);
      this.order_id = "FEE" + time;
      let obj = {
        appid: "wx9b285b6f35df0810",
        mch_id: "1616439027",
        order_name: "会员交费",
        order_id: this.order_id,
        grandTotal: this.member_fee,
        trade_type: "JSAPI",
        redirect_url: "www.tscsr.com/#/home",
        notify_url: "www.tscsr.com",
        openid: localStorage.getItem("openid"),
		party_id:this.party_id
      };
	  console.log("支付前查看参数",obj);
      let str = JSON.stringify(obj);
      let { data } = await payMembershipFee(str);
	  console.log("调试微信支付")
	  console.log("支付前查看参数",data);
      if (data.code == "0") {
        let that = this;
		console.log("查看参数",data);
        // let payParams = JSON.parse(data.map);
		console.log("微信支付调用",data.map)
        wx.config({
          debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
          appId: data.map.appId, // 必填，公众号的唯一标识
          timestamp: data.map.timeStamp, // 必填，生成签名的时间戳
          nonceStr: data.map.nonceStr, // 必填，生成签名的随机串
          signature: data.map.paySign, // 必填，签名
          jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
        });
        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ["chooseWXPay"],
            success: function (res) {
              console.log("seccess");
              console.log("hskdjskjk", res);
            },
            fail: function (res) {
              console.log("fail");
              console.log(res);
            },
          });
          wx.chooseWXPay({
            timestamp: data.map.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: data.map.nonceStr, // 支付签名随机串，不长于 32 位
            package: data.map.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.map.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: data.map.paySign, // 支付签名
            complete: (res) => {
              if (res.errMsg == "chooseWXPay:ok") {
                // 回调函数内容
                that.addOrderInfo();
              } else {
                this.$message.error("支付失败");
              }
            },
            // success: function (res) {  // 支付成功后的回调函数
            //   alert(res.errorMsg)
            //   that.addOrderInfo();
            // },
            // fail: function (res) {
            //   alert("支付失败");
            //   alert(res.errMsg);
            // }
          });
        });
        wx.error((err) => {
          alert(err);
        });
      } else {
        this.$message.error(data.content);
      }
    },
    //更新订单信息
    async addOrderInfo() {
      let obj = {
        amount: this.member_fee,
        order_ID: this.order_id,
        order_NAME: this.name + "的会员交费记录",
        party_ID: this.party_id,
        payment_METHOD: "0",
        status_ID: "1",
      };
      let str = JSON.stringify(obj);
      let { data } = await addOrderInfo(str);
      if (data.type == "success") {
        this.updateInvalidDate();
      } else {
        this.$message.error("创建订单失败");
      }
    },
    //更新起始日期和有效期
    async updateInvalidDate() {
      let type = "0";
      if (this.member_type == "企业会员") {
        type = "1";
      }
      let { data } = await updateInvalidDate({
        party_id: this.party_id,
        start_date: this.startDate,
        end_date: this.endDate,
        type: type,
      });
      if (data.type == "success") {
        this.$router.push({ name: "Home" });
        console.log(data.content);
      } else {
        this.$message.error(data.content);
      }
    },
    changeInfo() {
      this.$router.push({ name: "myInfo" }); //跳转到修改信息页
    },
    //更改级别
    changeClasses() {
      this.popup = true;
    },
    //确定更改
    async changeSubmit() {
      let member_type = "0";
      if (this.member_type == "企业会员") {
        member_type = "1";
      }
      let { data } = await updatePersonMemberClasses({
        member_type: member_type,
        party_id: this.party_id,
        number_classes: this.classes,
      });
      if (data.type == "success") {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.$router.push({ name: "Home" });
      } else {
        this.$message.error(data.content);
      }
    },
    //返回sss
    backTable() {
      this.popup = false;
    },
    //修改级别
    async updateClasses() {
      let obj = {
        amount: this.member_fee,
        order_ID: this.order_id,
        order_NAME: this.name + "的会员交费记录",
        party_ID: this.party_id,
        payment_METHOD: "0",
        status_ID: "1",
      };
      let str = JSON.stringify(obj);
      let { data } = await addOrderInfo(str);
      if (data.type == "success") {
        this.updateInvalidDate();
      } else {
        this.$message.error("创建订单失败");
      }
    },
  },
};
</script>
